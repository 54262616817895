import { Configuration, ConfigurationProvider } from './Interfaces';

const interpolationConst = '$(';

const overrideValueFromGlobalVars = (
  currentValue: any,
  globalVarValue: any,
  isArray = false
) => {
  if (!globalVarValue) {
    return currentValue;
  }

  if (globalVarValue.toString().startsWith(interpolationConst)) {
    return currentValue;
  }

  return isArray ? globalVarValue.split(' ') : globalVarValue;
};

const globalVarsConfigurationProvider: ConfigurationProvider<Configuration> = (
  configuration = {}
) => {
  const globalConfig = (window as any).REACT_APP_VARIABLES;
  if (!globalConfig) {
    return configuration;
  }

  return {
    ...configuration,
    IsStandalone: overrideValueFromGlobalVars(
      configuration.IsStandalone,
      globalConfig.IsStandalone
    ),
    LocalesBaseUrl: overrideValueFromGlobalVars(
      configuration.LocalesBaseUrl,
      globalConfig.LocalesBaseUrl
    ),
    MediaBaseUrl: overrideValueFromGlobalVars(
      configuration.MediaBaseUrl,
      globalConfig.MediaBaseUrl
    ),
    LocalesVersion: overrideValueFromGlobalVars(
      configuration.LocalesVersion,
      globalConfig.LocalesVersion
    ),
    ApiBaseUrl: overrideValueFromGlobalVars(
      configuration.ApiBaseUrl,
      globalConfig.ApiBaseUrl
    ),
    DesignSystemMediaBaseUrl: overrideValueFromGlobalVars(
      configuration.DesignSystemMediaBaseUrl,
      globalConfig.DesignSystemMediaBaseUrl
    ),
    LogosUrl: overrideValueFromGlobalVars(
      configuration.LogosUrl,
      globalConfig.LogosUrl
    ),
    Adb2c: {
      clientId: overrideValueFromGlobalVars(
        configuration.Adb2c?.clientId,
        globalConfig.ClientId
      ),
      authority: overrideValueFromGlobalVars(
        configuration.Adb2c?.authority,
        globalConfig.Authority
      ),
      authorityRegister: overrideValueFromGlobalVars(
        configuration.Adb2c?.authorityRegister,
        globalConfig.AuthorityRegister
      ),
      authoritySettings: overrideValueFromGlobalVars(
        configuration.Adb2c?.authoritySettings,
        globalConfig.AuthoritySettings
      ),
      knownAuthorities: overrideValueFromGlobalVars(
        configuration.Adb2c?.knownAuthorities,
        globalConfig.KnownAuthorities
      ),
      loginRedirectUrl: overrideValueFromGlobalVars(
        configuration.Adb2c?.loginRedirectUrl,
        globalConfig.LoginRedirectUrl
      ),
      logoutRedirectUrl: overrideValueFromGlobalVars(
        configuration.Adb2c?.logoutRedirectUrl,
        globalConfig.LogoutRedirectUrl
      ),
      settingsRedirectUrl: overrideValueFromGlobalVars(
        configuration.Adb2c?.settingsRedirectUrl,
        globalConfig.SettingsRedirectUrl
      ),
      apiPermissions: overrideValueFromGlobalVars(
        configuration.Adb2c?.apiPermissions,
        globalConfig.ApiPermissions,
        true
      ),
      tainaCertificationUrl: overrideValueFromGlobalVars(
        configuration.Adb2c?.tainaCertificationUrl,
        globalConfig.TainaCertificationUrl
      ),
    },
  };
};

export default globalVarsConfigurationProvider;
