import configuration from '../../app/configuration';

const isNewSol =
  window.location.pathname.includes('newsol') || configuration.IsStandalone;

const getUrl = (url?: string) =>
  url?.length
    ? `${
        configuration.IsStandalone ||
        url?.startsWith('/transactional/') ||
        url?.startsWith('/umbraco/') ||
        url?.startsWith('/help') ||
        isNewSol
          ? ''
          : '/transactional/authenticated/newsol'
      }${url}`
    : '';

export { isNewSol, getUrl };
