import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import BottomBar from './components/BottomBar';
import MobileMenu from './components/MobileMenu';
import TopBar from './components/TopBar';
import { HeaderProps } from './Interfaces';
import { useBreakpoints } from '../utils/useBreakpoints';
import Container from '../common/Container/Container';
import Spacings from '../theme/common/spacings';

const Header: React.FC<HeaderProps> = ({
  menuElements,
  isLoggedIn,
  accountLinks,
  logo,
  notifications,
  messages,
  mobileAccountLinks,
  buttonsSettings,
  themeVariant,
  additionalContent,
  hideLoginRegister,
  hideMobileMenu,
  sx = {},
}: HeaderProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const breakpoints = useBreakpoints(['smDown', 'smUp']);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const headerLogoHeight = 32;
  const centeredHeaderLogo: JSX.Element = (
    <Stack alignItems="center">
      <Box height={headerLogoHeight}>
        {logo.external ? (
          <a href={logo.url}>
            <img src={logo.image} alt="logo" height={headerLogoHeight} />
          </a>
        ) : (
          <Link to={logo.url}>
            <img src={logo.image} alt="logo" height={headerLogoHeight} />
          </Link>
        )}
      </Box>
    </Stack>
  );

  return (
    <AppBar
      elevation={0}
      sx={{
        background: 'white',
        color: 'grey.900',
        fontSize: 14,
        '& svg': {
          fontSize: 20,
        },
        height: 'auto',
        position: 'sticky',
        boxShadow: '0 1px 4px #19316f60',
        ...sx,
      }}
    >
      <Container>
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              padding: 0,
              minHeight: {
                xs: 64,
                sm: 72,
              },
            },
          }}
        >
          {centeredHeaderLogo}

          <Box sx={{ flexGrow: 1 }}>{additionalContent}</Box>

          <TopBar
            isLoggedIn={isLoggedIn}
            notifications={notifications}
            messages={messages}
            accountLinks={accountLinks}
            buttonsSettings={buttonsSettings}
            hideLoginRegister={hideLoginRegister}
          />
          {breakpoints.smDown && !hideMobileMenu && (
            <IconButton
              color="inherit"
              onClick={toggleDrawer}
              sx={{ marginLeft: Spacings.small3 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
        <Divider
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            borderColor: 'grey.400',
          }}
        />
        {isLoggedIn && breakpoints.smUp && (
          <BottomBar menuElements={menuElements} />
        )}
        {breakpoints.smDown && (
          <MobileMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            menuElements={menuElements}
            mobileAccountLinks={mobileAccountLinks}
            isLoggedIn={isLoggedIn}
            buttonsSettings={buttonsSettings}
            themeVariant={themeVariant}
          />
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
