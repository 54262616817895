import React from 'react';
import {
  CssBaseline,
  ScopedCssBaseline,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import merge from 'lodash/merge';
import { Theme } from '@mui/system/createTheme';

import configuration from '../../../../app/configuration';
import getEqMainThemeOptions from './options/eqMainThemeOptions';
import getSolThemeOptions from './options/solThemeOptions';

export interface SolTheme extends Theme {
  baselines: string[];
  baseMediaUrl: string;
  external: {
    bp: {
      palette: {
        primary: {
          main: string;
        };
      };
    };
    comcast: {
      palette: {
        primary: {
          main: string;
        };
      };
    };
  };
}

export const getSolTheme: () => SolTheme = () => {
  const eqMainThemeOptions = getEqMainThemeOptions();
  const eqMainTheme = createTheme(eqMainThemeOptions);
  const solThemeOptions = getSolThemeOptions(eqMainTheme);

  return merge(eqMainTheme, solThemeOptions);
};

export const solTheme = getSolTheme();

const SolThemeProvider = ({
  children,
  baseMediaUrl = undefined,
}: {
  children: React.ReactNode;
  baseMediaUrl?: string;
}) => {
  if (baseMediaUrl) {
    solTheme.baseMediaUrl = baseMediaUrl;
  }

  return (
    <ThemeProvider theme={solTheme}>
      {configuration.IsStandalone && <CssBaseline />}
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default SolThemeProvider;
