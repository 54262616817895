import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { useAuth } from '../../Auth';
import { decorateWithDesignSystemMediaBaseUrl } from '../../../app/configuration/Localization';
import DsFooter, { FooterColumnPanelProps } from '../ds/Footer';

const Footer = ({ sx = {} }: { sx?: SxProps }) => {
  const { t } = useTranslation();
  const data = t('footer', { returnObjects: true });
  const { isAuthenticated } = useAuth();

  const footerCopyright = {
    ...data.footerCopyright,
    copyrightText: data.footerCopyright.copyrightText.replace(
      '{year}',
      new Date().getFullYear().toString()
    ),
    logoUrl: decorateWithDesignSystemMediaBaseUrl(data.footerCopyright.logoUrl),
  };

  const footerColumn = data.footerColumn.map(
    (item) =>
      ({
        ...item,
        links: item.links.map((linkItem) => ({
          ...linkItem,
          link:
            isAuthenticated && linkItem.authenticatedLink !== undefined
              ? linkItem.authenticatedLink
              : linkItem.link,
        })),
      } as FooterColumnPanelProps)
  );

  return (
    <DsFooter
      footerCopyright={footerCopyright}
      footerColumn={footerColumn}
      sx={{
        zIndex: 100,
        '.MuiLink-root': {
          color: 'white',
          textDecorationColor: '#fff0',
          ':hover': {
            textDecorationColor: 'inherit',
          },
        },
        ...sx,
      }}
    />
  );
};

export default Footer;
