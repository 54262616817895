/* eslint-disable no-param-reassign */
import { AgreementType } from '@equs/sp-api-client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../app/apiClient';

export type AgreementsSliceState = {
  isLoading: boolean;
  onlineAccess?: string;
  esign?: string;
};

const initialState: AgreementsSliceState = {
  isLoading: false,
  onlineAccess: undefined,
  esign: undefined,
};

export const fetchOnlineAccessAgreement = createAsyncThunk(
  'agreements/onlineAccess',
  async () => {
    const result = await apiClient.agreement_GetHtml(
      AgreementType.OnlineAccess
    );
    return result;
  }
);

export const fetchEsignConsent = createAsyncThunk(
  'agreements/esign',
  async () => {
    const result = await apiClient.agreement_GetHtml(AgreementType.ESign);
    return result;
  }
);

const agreementssSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOnlineAccessAgreement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOnlineAccessAgreement.fulfilled, (state, action) => ({
      isLoading: false,
      onlineAccess: action.payload.html,
    }));
    builder.addCase(fetchOnlineAccessAgreement.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchEsignConsent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEsignConsent.fulfilled, (state, action) => ({
      isLoading: false,
      esign: action.payload.html,
    }));
    builder.addCase(fetchEsignConsent.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default agreementssSlice.reducer;
