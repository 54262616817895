import axios from 'axios';
import { SPCoreClient } from '@equs/sp-api-client/dist';
import RuntimeConfigurationProvider from './configuration/RuntimeConfigurationProvider';
import msalInstance from './msalInstance';
import configuration from './configuration';

const axiosInstance = axios.create({
  transformResponse: (data) => data, // tell axios to not parse json
});

const { ApiBaseUrl } = new RuntimeConfigurationProvider().getConfiguration();
const apiClient = new SPCoreClient(ApiBaseUrl, axiosInstance);

if (configuration.IsStandalone) {
  const isLoggedIn = !!msalInstance.getAllAccounts()[0];

  axiosInstance.interceptors.request.use((requestConfig) => {
    const isNewApi = requestConfig.url?.includes('/umbraco/api/spcore');
    const isNewApiDev =
      requestConfig.url?.includes('/api_backend') ||
      requestConfig.url?.includes('localhost');

    if (!isNewApi && !isNewApiDev) {
      return requestConfig;
    }

    if (!isLoggedIn || ApiBaseUrl === undefined) return requestConfig;

    return new Promise((resolve) => {
      const request = {
        scopes: configuration.Adb2c?.apiPermissions || [],
        account: msalInstance.getAllAccounts()[0],
        forceRefresh: false,
      };
      msalInstance.acquireTokenSilent(request).then((tokenResponse) => {
        if (
          requestConfig.url &&
          ApiBaseUrl &&
          requestConfig.url.startsWith(ApiBaseUrl)
        ) {
          // eslint-disable-next-line no-param-reassign
          requestConfig.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        }

        resolve(requestConfig);
      });
    });
  });
}

export default apiClient;
