/* eslint-disable */
import { useEffect, useState } from 'react';
import { useGetFeatureFlagsQuery } from '../../slices/featureFlags/faetureFlagsSlice';

const FeatureFlags = {
  BUY: 'buy',
  ACCOUNT_BALANCE: 'accountBalance',
  IDV: 'idv',
  MANAGE_DIVIDENDS: 'manageDividends',
};

function useFeatureFlags() {
  const cacheKey = 'cached-feature-flags';

  const { data, isFetching } = useGetFeatureFlagsQuery();
  const [flags, setFlags] = useState(() => {
    const cachedFlags = localStorage.getItem(cacheKey);
    return cachedFlags ? JSON.parse(cachedFlags) : undefined;
  });

  useEffect(() => {
    if (!isFetching && data) {
      localStorage.setItem(cacheKey, JSON.stringify(data));
      setFlags(data);
    }
  }, [isFetching]);

  return {
    flags,
    isLoaded: flags || !isFetching,
  };
}

export default useFeatureFlags;

export { FeatureFlags };
