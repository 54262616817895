import React from 'react';
import { AppBarProps } from '@mui/material';

export interface HeaderProps {
  menuElements: Array<HeaderLink>;
  accountLinks: Array<HeaderLink>;
  mobileAccountLinks: Array<HeaderLink>;
  isLoggedIn: boolean;
  messages: TopBarProps['messages'];
  notifications: TopBarProps['notifications'];
  logo: LogoSettings;
  buttonsSettings: ButtonSettings;
  themeVariant: ThemeVariant;
  additionalContent?: React.ReactNode;
  hideLoginRegister?: boolean;
  hideMobileMenu?: boolean;
  sx?: AppBarProps['sx'];
}

export enum ThemeVariant {
  dark,
  light,
}

export interface LogoSettings {
  image: string;
  url: string;
  external?: boolean;
}

export interface ButtonSettings {
  registerLink: string;
  registerButtonName: string;
  onRegisterClick?: (event: React.MouseEvent<HTMLElement>) => void;
  registerExternal?: boolean;
  loginLink: string;
  loginButtonName: string;
  onLoginClick?: (event: React.MouseEvent<HTMLElement>) => void;
  loginExternal?: boolean;
}

export interface TopBarProps {
  accountLinks: Array<HeaderLink>;
  isLoggedIn: boolean;
  notifications: {
    url: string;
    externalUrl?: boolean;
    hasNotifications: boolean;
  };
  messages: {
    url: string;
    externalUrl?: boolean;
    hasMessages: boolean;
  };
  buttonsSettings: ButtonSettings;
  hideLoginRegister?: boolean;
}

export interface BottomBarProps {
  menuElements: Array<HeaderLink>;
}

export interface MobileMenuProps {
  isDrawerOpen: boolean;
  toggleDrawer: any;
  menuElements: Array<HeaderLink>;
  mobileAccountLinks: Array<HeaderLink>;
  buttonsSettings: ButtonSettings;
  isLoggedIn: boolean;
  themeVariant: ThemeVariant;
}

export interface HeaderLink {
  index: number;
  label: string;
  url?: string;
  externalUrl?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: any;
  iconUrl?: string;
  disabled?: boolean;
  subLinks?: Array<HeaderLink>;
}

export interface MobileMenuItemProps {
  linkConfig: HeaderLink;
  toggleDrawer: any;
  isBottomMenu: boolean;
  onClick?: any;
  isOpen: boolean;
  themeVariant: ThemeVariant;
  isAccountLink?: boolean;
}
