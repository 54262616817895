import React from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from 'flagged';
import Header from './common/components/Header';
import useFutureFlags from './common/hooks/featureFlags/useFeatureFlags';

interface Props {
  container: HTMLElement;
}

const HeaderPortal = ({ container }: Props) => {
  const { flags } = useFutureFlags();
  return container
    ? ReactDOM.createPortal(
        <FlagsProvider features={flags}>
          <Header />
        </FlagsProvider>,
        container
      )
    : null;
};

export default HeaderPortal;
