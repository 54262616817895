/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  UserAccountAddress,
  UserAccountInformation,
  UserAccountSeasonalAddress,
  ZipCode,
} from '@equs/sp-api-client';
import apiClient from '../../../app/apiClient';
import {
  ChangePrimaryAccountAddress,
  ChangeSeasonalAccountAddress,
  CheckIsAddressPending,
} from './Interfaces';

type SliceState = {
  isLoading: boolean;
  isError: boolean;
  isUpdatingPrimaryAddress: boolean;
  isUpdatingPrimaryAddressError: boolean;
  isUpdatingSeasonalAddress: boolean;
  isUpdatingSeasonalAddressError: boolean;
  isSeasonalAddressBatchPending: boolean;
  isPrimaryAddressBatchPending: boolean;
  isAddressPreviedLoading: boolean;
  list: UserAccountInformation[];
  redirectUrl: string;
};

const initialState: SliceState = {
  isLoading: false,
  isError: false,
  isUpdatingPrimaryAddress: false,
  isUpdatingPrimaryAddressError: false,
  isUpdatingSeasonalAddress: false,
  isUpdatingSeasonalAddressError: false,
  isSeasonalAddressBatchPending: false,
  isPrimaryAddressBatchPending: false,
  isAddressPreviedLoading: false,
  list: [],
  redirectUrl: '',
};

export const fetchAccounts = createAsyncThunk(
  'accountManagement/address/load',
  async () => {
    const { accounts } = await apiClient.accountsInformation_GetAccounts();
    return accounts ?? [];
  }
);

const getPrimaryAddressPreview = async (
  userId: string,
  accountNumber: number,
  userAddress: UserAccountAddress
): Promise<UserAccountAddress> =>
  apiClient.primaryAddress_GetPreview(userId, accountNumber, userAddress);

const getSeasonalAddressPreview = async (
  userId: string,
  accountNumber: number,
  userAddress: UserAccountSeasonalAddress
): Promise<UserAccountSeasonalAddress> =>
  apiClient.seasonalAddress_GetPreview(userId, accountNumber, userAddress);

const isPrimaryAddressBatchPending = async (
  userId: string,
  accountNumber: number
): Promise<boolean> =>
  apiClient.primaryAddress_IsPrimaryAddressBatchPending(userId, accountNumber);

const isSeasonalAddressBatchPending = async (
  userId: string,
  accountNumber: number
): Promise<boolean> =>
  apiClient.seasonalAddress_IsSeasonalAddressBatchPending(
    userId,
    accountNumber
  );

export const changePrimaryAccountAddress = createAsyncThunk(
  'accountManagement/primaryAddress/update',
  async ({
    userId,
    accountId,
    addressLines,
    city,
    countryCode,
    state,
    primaryZipCode,
  }: ChangePrimaryAccountAddress) => {
    await apiClient.primaryAddress_Update(userId!, accountId!, {
      addressLines,
      city,
      countryCode,
      state,
      zipCode: { primary: primaryZipCode } as ZipCode,
    } as UserAccountAddress);
  }
);

export const changeSeasonalAccountAddress = createAsyncThunk(
  'accountManagement/seasonalAddress/update',
  async ({
    userId,
    accountId,
    addressLines,
    state,
    city,
    countryCode,
    primaryZipCode,
    start,
    end,
    isRecurring,
    isActive,
  }: ChangeSeasonalAccountAddress) => {
    await apiClient.seasonalAddress_Update(userId!, accountId!, {
      addressLines,
      city,
      countryCode,
      zipCode: { primary: primaryZipCode } as ZipCode,
      start: new Date(start!),
      end: new Date(end!),
      state,
      isRecurring,
      isActive,
    } as UserAccountSeasonalAddress);
  }
);

export const createSeasonalAccountAddress = createAsyncThunk(
  'accountManagement/seasonalAddress/create',
  async ({
    userId,
    accountId,
    addressLines,
    state,
    city,
    countryCode,
    primaryZipCode,
    start,
    end,
    isRecurring,
    isActive,
  }: ChangeSeasonalAccountAddress) => {
    await apiClient.seasonalAddress_Create(userId!, accountId!, {
      addressLines,
      city,
      countryCode,
      zipCode: { primary: primaryZipCode } as ZipCode,
      start: new Date(start!),
      end: new Date(end!),
      state,
      isRecurring,
      isActive,
    } as UserAccountSeasonalAddress);
  }
);

export const deleteSeasonalAddress = createAsyncThunk(
  'accountManagament/seasonalAddress/delete',
  async ({ accountId, accountNumber }: any) => {
    await apiClient.seasonalAddress_Delete(accountId, accountNumber);
  }
);

export const fetchPrimaryAddressPreview = createAsyncThunk(
  'accountManagament/primaryAddress/preview',
  async ({
    userId,
    accountId,
    addressLines,
    state,
    city,
    countryCode,
    primaryZipCode,
  }: ChangePrimaryAccountAddress) =>
    getPrimaryAddressPreview(userId!, accountId!, {
      addressLines,
      city,
      countryCode,
      state,
      zipCode: { primary: primaryZipCode } as ZipCode,
    } as UserAccountAddress)
);

export const fetchSeasonalAddressPreview = createAsyncThunk(
  'accountManagement/seasonalAddress/preview',
  async ({
    userId,
    accountId,
    addressLines,
    city,
    state,
    countryCode,
    primaryZipCode,
    start,
    end,
    isRecurring,
    isActive,
  }: ChangeSeasonalAccountAddress) =>
    getSeasonalAddressPreview(userId!, accountId!, {
      addressLines,
      city,
      countryCode,
      zipCode: { primary: primaryZipCode } as ZipCode,
      start: new Date(start!),
      end: new Date(end!),
      state,
      isRecurring,
      isActive,
    } as UserAccountSeasonalAddress)
);

export const checkIsPrimaryAddressBatchPending = createAsyncThunk(
  'accountManagement/primaryAddress/is-primary-addres-batch-pendning',
  async ({ userId, accountId }: CheckIsAddressPending) =>
    isPrimaryAddressBatchPending(userId, accountId)
);

export const checkIsSeasonalAddressBatchPending = createAsyncThunk(
  'accountManagement/seasonalAddress/is-seasonal-addres-batch-pendning',
  async ({ userId, accountId }: CheckIsAddressPending) =>
    isSeasonalAddressBatchPending(userId, accountId)
);

const fulfilledPrimaryAddressReducer = (state: SliceState) => {
  state.isUpdatingPrimaryAddress = false;
  state.isUpdatingPrimaryAddressError = false;
};

const rejectionPrimaryAddressReducer = (state: SliceState) => {
  state.isUpdatingPrimaryAddress = false;
  state.isUpdatingPrimaryAddressError = true;
};

const pendingPrimaryAddressReducer = (state: SliceState) => {
  state.isUpdatingPrimaryAddress = true;
  state.isUpdatingPrimaryAddressError = false;
};

const fulfilledSeasonalAddressReducer = (state: SliceState) => {
  state.isUpdatingSeasonalAddress = false;
  state.isUpdatingSeasonalAddressError = false;
};

const rejectionSeasonalAddressReducer = (state: SliceState) => {
  state.isUpdatingSeasonalAddress = false;
  state.isUpdatingSeasonalAddressError = true;
};

const pendingSeasonalAddressReducer = (state: SliceState) => {
  state.isUpdatingSeasonalAddress = true;
  state.isUpdatingSeasonalAddressError = false;
};

const accountManagementSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    updateRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.list = action.payload || [];
    });
    builder.addCase(fetchAccounts.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(changePrimaryAccountAddress.fulfilled, (state) => {
      fulfilledPrimaryAddressReducer(state);
    });
    builder.addCase(changePrimaryAccountAddress.rejected, (state) => {
      rejectionPrimaryAddressReducer(state);
    });
    builder.addCase(changePrimaryAccountAddress.pending, (state) => {
      pendingPrimaryAddressReducer(state);
    });
    builder.addCase(changeSeasonalAccountAddress.fulfilled, (state) => {
      fulfilledSeasonalAddressReducer(state);
    });
    builder.addCase(changeSeasonalAccountAddress.rejected, (state) => {
      rejectionSeasonalAddressReducer(state);
    });
    builder.addCase(changeSeasonalAccountAddress.pending, (state) => {
      pendingSeasonalAddressReducer(state);
    });
    builder.addCase(createSeasonalAccountAddress.rejected, (state) => {
      rejectionSeasonalAddressReducer(state);
    });
    builder.addCase(createSeasonalAccountAddress.pending, (state) => {
      pendingSeasonalAddressReducer(state);
    });
    builder.addCase(deleteSeasonalAddress.rejected, (state) => {
      rejectionSeasonalAddressReducer(state);
    });
    builder.addCase(deleteSeasonalAddress.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const properAccount = state.list.filter(
        (x) => x.accountNumber === arg.accountNumber
      )[0];
      const accIndex = state.list.findIndex(
        (x) => x.accountNumber === arg.accountNumber
      );

      const modifiedList = [
        ...state.list.filter((x) => x.accountNumber !== arg.accountNumber),
      ];
      modifiedList.splice(accIndex, 0, {
        ...properAccount,
        seasonalAddress: undefined,
      });

      return {
        ...state,
        isUpdatingSeasonalAddress: false,
        isUpdatingSeasonalAddressError: false,
        list: modifiedList,
      };
    });
    builder.addCase(deleteSeasonalAddress.pending, (state) => {
      pendingSeasonalAddressReducer(state);
    });
    builder.addCase(fetchPrimaryAddressPreview.pending, (state) => {
      state.isAddressPreviedLoading = true;
    });
    builder.addCase(fetchPrimaryAddressPreview.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const matchingAccount = state.list.find(
        (x) => x.accountNumber === arg.accountId
      );
      if (matchingAccount && matchingAccount?.address) {
        matchingAccount.address.isUndeliverableAddress =
          action.payload.isUndeliverableAddress;
        matchingAccount.address.fullAddressLines =
          action.payload.fullAddressLines;
        matchingAccount.address.city = arg.city;
        matchingAccount.address.countryCode = arg.countryCode;
        matchingAccount.address.state = arg.state;
        if (matchingAccount.address.zipCode) {
          matchingAccount.address.zipCode.primary = arg.primaryZipCode;
        }
        matchingAccount.address.addressLines = action.payload.addressLines;
      }
      state.isAddressPreviedLoading = false;
    });
    builder.addCase(fetchPrimaryAddressPreview.rejected, (state) => {
      rejectionPrimaryAddressReducer(state);
    });
    builder.addCase(
      checkIsPrimaryAddressBatchPending.fulfilled,
      (state, action) => {
        state.isPrimaryAddressBatchPending = action.payload;
      }
    );
    builder.addCase(checkIsPrimaryAddressBatchPending.rejected, (state) => {
      state.isPrimaryAddressBatchPending = false;
    });
    builder.addCase(
      checkIsSeasonalAddressBatchPending.fulfilled,
      (state, action) => {
        state.isSeasonalAddressBatchPending = action.payload;
      }
    );
    builder.addCase(checkIsSeasonalAddressBatchPending.rejected, (state) => {
      state.isSeasonalAddressBatchPending = false;
    });
    builder.addCase(fetchSeasonalAddressPreview.pending, (state) => {
      pendingSeasonalAddressReducer(state);
    });
    builder.addCase(fetchSeasonalAddressPreview.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const matchingAccount = state.list.find(
        (x) => x.accountNumber === arg.accountId
      );

      if (matchingAccount && matchingAccount?.seasonalAddress === undefined) {
        matchingAccount.seasonalAddress = {};
      }
      if (matchingAccount && matchingAccount?.seasonalAddress) {
        matchingAccount.seasonalAddress.city = arg.city;
        matchingAccount.seasonalAddress.countryCode = arg.countryCode;
        matchingAccount.seasonalAddress.isActive = arg.isActive;
        matchingAccount.seasonalAddress.end = arg.end;
        matchingAccount.seasonalAddress.start = arg.start;
        matchingAccount.seasonalAddress.state = arg.state;
        if (matchingAccount.seasonalAddress.zipCode) {
          matchingAccount.seasonalAddress.zipCode.primary = arg.primaryZipCode;
        }
        matchingAccount.seasonalAddress.isRecurring = arg.isRecurring;
        matchingAccount.seasonalAddress.addressLines =
          action.payload?.addressLines;
      }

      state.isUpdatingSeasonalAddress = false;
      state.isUpdatingSeasonalAddressError = false;
    });
    builder.addCase(fetchSeasonalAddressPreview.rejected, (state) => {
      rejectionSeasonalAddressReducer(state);
    });
  },
});

const { actions, reducer } = accountManagementSlice;
export const { updateRedirectUrl } = actions;
export default reducer;
