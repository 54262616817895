import balanceSummary from '../../../../locales/en/pages/accountBalance/balanceSummary.json';
import accountBalanceReports from '../../../../locales/en/pages/accountBalance/accountBalanceReports.json';

export enum AccountBalanceReportResourceKeys {
  accountBalanceReports = 'pages/accountBalance/accountBalanceReports',
  balanceSummary = 'pages/accountBalance/balanceSummary',
}

export type AccountBalanceReportResources = {
  [AccountBalanceReportResourceKeys.accountBalanceReports]: typeof accountBalanceReports;
  [AccountBalanceReportResourceKeys.balanceSummary]: typeof balanceSummary;
};
