import React from 'react';
import { useTheme } from '@mui/material/styles';

interface Props {
  isOpen: boolean;
  className?: string;
  style?: any;
}
const KeyboardArrowIcon: React.FC<Props> = ({
  isOpen,
  className,
  style,
}: Props) => {
  const theme = useTheme();
  return isOpen ? (
    <img
      src={`${theme.baseMediaUrl}/v1/icons-small-default-arrow-collapse.svg`}
      alt="arrow down"
      className={className}
      style={style}
    />
  ) : (
    <img
      src={`${theme.baseMediaUrl}/v1/icon-small-arrow-expand.svg`}
      alt="arrow up"
      className={className}
      style={style}
    />
  );
};

KeyboardArrowIcon.defaultProps = {
  className: '',
  style: {},
};

export default KeyboardArrowIcon;
