export enum AlertType {
  None = 'None',
  OutstandingChecks = 'OutstandingChecks',
  UndeliverableAddress = 'UndeliverableAddress',
  TaxCertified = 'TaxCertified',
  FirstBNotice = 'FirstBNotice',
  SecondBNotice = 'SecondBNotice',
  CNotice = 'CNotice',
  AccountReCertification = 'AccountReCertification',
}

export interface Alert {
  type: AlertType;
  accountName?: string;
  accountNumber?: number;
  description: string;
  action: string;
  content?: string;
  moreInfoLabel?: string;
  lessInfoLabel?: string;
}

export interface AlertDescription {
  label: string;
  action: string;
  content: string;
  moreInfoLabel: string;
  lessInfoLabel: string;
}

export interface AlertsLocalization {
  undeliverableAddress: AlertDescription;
  outstandingChecks: AlertDescription;
  firstBNotice: AlertDescription;
  secondBNotice: AlertDescription;
  cNotice: AlertDescription;
  taxCertExpiring: AlertDescription;
  taxIdUncertified: AlertDescription;
}
