/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AlertService from '../../services/alertService/AlertsService';
import { Alert } from '../../services/alertService/Interfaces';
import SessionService from '../../services/sessionService/SessionService';
import {
  MARKED_ALERTS,
  ALERTS_LOAD,
  ALERTS,
  MARKED_RESOLVED_ALERTS,
} from '../../constants/alertConstants';

export type SliceState = {
  isLoading: boolean;
  readItemsTypes: string[];
  all: Alert[];
  alertResolved: number[];
};

const initialState: SliceState = {
  isLoading: false,
  readItemsTypes: [],
  all: [],
  alertResolved: [],
};

export const fetchAlertsByUserId = createAsyncThunk(ALERTS_LOAD, async () =>
  new AlertService().getAll()
);

const alertsSlice = createSlice({
  name: ALERTS,
  initialState,
  reducers: {
    addReadAlert: (state, action) => {
      state.readItemsTypes = [...state.readItemsTypes, action.payload];
    },
    resolveAlert: (state, action) => {
      state.alertResolved = [...state.alertResolved, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlertsByUserId.pending, (state) => {
      state.all = [];
      state.isLoading = true;
    });
    builder.addCase(fetchAlertsByUserId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.all = action.payload || [];
      state.readItemsTypes =
        SessionService.loadFromSession(MARKED_ALERTS) || [];
      state.alertResolved =
        SessionService.loadFromSession(MARKED_RESOLVED_ALERTS) || [];
    });
    builder.addCase(fetchAlertsByUserId.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
const { actions, reducer } = alertsSlice;
export const { addReadAlert, resolveAlert } = actions;
export default reducer;
