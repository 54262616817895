import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../app/apiClient';

export type RegistrationSliceState = {
  isLoading: boolean;
  isError: boolean;
  username: string;
};

export const initialState: RegistrationSliceState = {
  isLoading: false,
  isError: false,
  username: '',
};

export const fetchUserDetailsById = createAsyncThunk(
  'users/getDetails',
  async () => {
    const response = await apiClient.users_GetDetails();

    return response.userName;
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetailsById.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,
      username: action.payload || '',
    }));
    builder.addCase(fetchUserDetailsById.rejected, (state) => ({
      ...state,
      isLoading: false,
      isError: true,
    }));
    builder.addCase(fetchUserDetailsById.pending, (state) => ({
      ...state,
      isLoading: true,
      isError: false,
    }));
  },
});

export default userSlice.reducer;
