/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddressFormat } from '@equs/sp-api-client';
import apiClient from '../../../app/apiClient';
import { GetAddressFormat } from './Interfaces';

export type AddressFormatSliceState = {
  isLoading: boolean;
  addressFormat?: AddressFormat;
};

const initialState: AddressFormatSliceState = {
  isLoading: false,
  addressFormat: undefined,
};

const getFormat = async (
  userId: string,
  countryCode: string,
  accountNumber: number,
  registrationCode: string
): Promise<AddressFormat> =>
  apiClient.addressFormat_GetFormat(
    userId,
    countryCode,
    accountNumber,
    registrationCode
  );

const getFormatAnonymous = async (
  countryCode: string,
  registrationCode: string
): Promise<AddressFormat> =>
  apiClient.addressFormat_GetFormatForCountry(countryCode, registrationCode);

export const fetchAddressFormat = createAsyncThunk(
  'addressFormat/load',
  async ({
    userId,
    countryCode,
    accountNumber,
    registrationCode,
  }: GetAddressFormat) => {
    if (!userId?.length && !accountNumber) {
      return getFormatAnonymous(countryCode!, registrationCode!);
    }
    return getFormat(userId!, countryCode!, accountNumber!, registrationCode!);
  }
);

const addressFormatSlice = createSlice({
  name: 'addressFormat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAddressFormat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addressFormat = action.payload;
    });
    builder.addCase(fetchAddressFormat.pending, (state) => {
      state.isLoading = true;
    });
  },
});
export default addressFormatSlice.reducer;
