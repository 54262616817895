/* eslint-disable no-param-reassign */
import apiClient from '../../../app/apiClient';
import emptyApi from '../emptyApi';

export type FeatureFlag = {
  [key: string]: boolean;
};

const getFeatureFlags = async () =>
  apiClient
    .features_GetFeatures()
    .then((response) => ({ data: response }))
    .catch((reason) => ({
      error: reason,
      data: {} as FeatureFlag,
    }));

export const featureFlagsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getFeatureFlags: build.query<FeatureFlag, void>({
      queryFn: getFeatureFlags,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFeatureFlagsQuery } = featureFlagsApi;
