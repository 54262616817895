const ADD_READ_ALERTS = 'alerts/addReadAlert';
const MARKED_ALERTS = 'markedAlerts';
const ALERTS_LOAD = 'alerts/load';
const ALERTS = 'alerts';
const ADD_RESOLVED_ALERTS = 'alerts/resolveAlert';
const MARKED_RESOLVED_ALERTS = 'resolvedAlerts';
export {
  MARKED_ALERTS,
  ALERTS_LOAD,
  ALERTS,
  ADD_READ_ALERTS,
  ADD_RESOLVED_ALERTS,
  MARKED_RESOLVED_ALERTS,
};
