import React from 'react';
import Box from '@mui/material/Box';
import CopyrightPanel from './components/CopyrightPanel';
import ColumnPanel from './components/ColumnPanel';
import { FooterProps } from './Interfaces';
import { useBreakpoints } from '../utils/useBreakpoints';
import Container from '../common/Container/Container';
import styles from './DsFooter.styles';

const Footer: React.FC<FooterProps> = ({
  footerCopyright,
  footerColumn,
  sx = {},
}: FooterProps) => {
  const breakpoints = useBreakpoints(['mdDown', 'mdUp']);
  const copyrightPanel = (
    <CopyrightPanel
      logoUrl={footerCopyright.logoUrl}
      label={footerCopyright.label}
      copyrightText={footerCopyright.copyrightText}
      logoAlt={footerCopyright.logoAlt}
    />
  );

  return (
    <Box sx={{ ...styles.background, ...sx }}>
      <Container>
        <Box sx={styles.navigation}>
          {breakpoints.mdUp && copyrightPanel}
          {footerColumn.map((item) => (
            <ColumnPanel
              key={item.label}
              label={item.label}
              links={item.links}
            />
          ))}
        </Box>
        {breakpoints.mdDown && copyrightPanel}
      </Container>
    </Box>
  );
};
export default Footer;
