import { Theme, ThemeOptions } from '@mui/system/createTheme';
import linkBase from '../common/linkBase';

export interface SolThemeOptions extends ThemeOptions {
  baselines: string[];
  baseMediaUrl: string;
  external: {
    bp: {
      palette: {
        primary: {
          main: string;
        };
      };
    };
    comcast: {
      palette: {
        primary: {
          main: string;
        };
      };
    };
  };
}

const getSolThemeOptions: (eqMainTheme: Theme) => SolThemeOptions = (
  eqMainTheme
) => ({
  baselines: ['20px', '28px'],
  baseMediaUrl: 'https://eqsolintusapp.blob.core.windows.net/web-resources',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        p: {
          margin: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#19316F',
          '& .MuiTooltip-arrow': {
            color: '#19316F',
          },
        },
      },
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          ...(eqMainTheme.components!.MuiCssBaseline!.styleOverrides as any),
          ...(eqMainTheme.components!.MuiCssBaseline!.styleOverrides as any)
            .body,
          color: 'black',
          '&:empty': {
            minHeight: 0,
          },
          '*::selection': {
            backgroundColor: '#D8E3FF',
          },
          a: {
            ...linkBase,
          },
          button: {
            '&:focus-visible': {
              outline: '5px auto',
              outlineColor: 'black',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          ...(eqMainTheme.components!.MuiCssBaseline!.styleOverrides as any)
            .body,
          a: {
            ...linkBase,
            '&.MuiMenuItem-root': {
              color: 'black',
              textDecoration: 'none',
            },
          },
          '&.MuiDrawer-root a': {
            color: 'black',
            textDecoration: 'none',
          },
          button: {
            '&:focus-visible': {
              outline: '5px auto',
              outlineColor: 'black',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
          '.PrivatePickersYear-root button': { fontSize: '1rem' },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        PaperProps: {
          sx: {
            '.PrivatePickersYear-root button': { fontSize: '1rem' },
          },
        },
      },
    },
  },
  external: {
    bp: {
      palette: {
        primary: {
          main: '#009B00',
        },
      },
    },
    comcast: {
      palette: {
        primary: {
          main: '#0088D2',
        },
      },
    },
  },
});

export default getSolThemeOptions;
