import { ConfigurationProvider, MsalConfiguration } from './Interfaces';

const msalConfigurationProvider: ConfigurationProvider<MsalConfiguration> = (
  configuration = {}
) => {
  const Msal = {
    auth: {
      clientId: configuration?.Adb2c?.clientId || '',
      authority: configuration?.Adb2c?.authority || '',
      authorityRegister: configuration?.Adb2c?.authorityRegister || '',
      authoritySettings: configuration?.Adb2c?.authoritySettings || '',
      knownAuthorities: (configuration?.Adb2c?.knownAuthorities || '').split(
        ','
      ),
      redirectUri: configuration?.Adb2c?.loginRedirectUrl || '',
      postLogoutRedirectUri: configuration?.Adb2c?.logoutRedirectUrl || '',
      settingsRedirectUrl: configuration?.Adb2c?.settingsRedirectUrl || '',
      tainaCertificationUrl: configuration?.Adb2c?.tainaCertificationUrl || '',
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };

  return {
    ...configuration,
    Msal,
  };
};

export default msalConfigurationProvider;
