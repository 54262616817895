/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../app/apiClient';
import SessionService from '../../services/sessionService/SessionService';
import {
  MARKED_MESSAGES_IDS,
  MESSAGES_LOAD,
  MESSAGES,
  CONTACT_QUESTIONS,
  MESSAGES_ACCOUNTS,
  MESSAGE_NEW,
} from '../../constants/messageConstants';
import { IContactQuestion } from '../../../pages/messages/Interfaces';
import {
  IMessageAccounts,
  IMessageContactQuestions,
  IMessages,
  INewMessage,
} from './interfaces';

type SliceState = {
  newMessage: INewMessage;
  accounts: IMessageAccounts;
  messages: IMessages;
  contactQuestions: IMessageContactQuestions;
};

const initialState: SliceState = {
  messages: {
    list: [],
    markedMessagesIds: [],
    isError: false,
    isLoading: false,
  },
  accounts: {
    list: [],
    isError: false,
    isLoading: false,
  },
  newMessage: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  contactQuestions: {
    list: [],
    isError: false,
    isLoading: false,
  },
};

export const fetchMessagesByUserId = createAsyncThunk(
  MESSAGES_LOAD,
  async () => {
    const response = await apiClient.message_GetMessages();
    return response.messages;
  }
);
export const fetchContactQuestionsByUserId = createAsyncThunk(
  CONTACT_QUESTIONS,
  async () => apiClient.message_GetContactQuestions()
);
export const fetchNewMessageAccountsByUserId = createAsyncThunk(
  MESSAGES_ACCOUNTS,
  async () => {
    const response = await apiClient.accountsInformation_GetAccountsCompanies();
    return response.accounts;
  }
);

export const newMessageSend = createAsyncThunk(
  MESSAGE_NEW,
  async (request: any) => {
    try {
      await apiClient.message_ActionCreateNewMessage(request.newMessage);
      return true;
    } catch (error) {
      return false;
    }
  }
);
const messagesSlice = createSlice({
  name: MESSAGES,
  initialState,
  reducers: {
    deleteMessages(state, action) {
      const ids = action.payload as number[];
      state.messages.list = state.messages.list.filter(
        (i) => ids.indexOf(i.messageId || 0) < 0
      );
    },
    addReadMessage: (state, action) => {
      state.messages.markedMessagesIds = [
        ...state.messages.markedMessagesIds,
        action.payload,
      ];
    },
    clearSuccessMessage: (state, action) => {
      state.newMessage.isSuccess = action.payload;
    },
    updateReadStatus: (state, action) => {
      const id = action.payload as number;
      state.messages = {
        ...state.messages,
        list: state.messages.list.map((content) =>
          content.messageId === id ? { ...content, unread: false } : content
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesByUserId.pending, (state) => {
        state.messages.isLoading = true;
        state.messages.isError = false;
        state.messages.list = [];
      })
      .addCase(fetchMessagesByUserId.fulfilled, (state, action) => {
        state.messages.isLoading = false;
        state.messages.isError = false;
        state.messages.list = action.payload || [];
        state.messages.markedMessagesIds =
          SessionService.loadFromSession(MARKED_MESSAGES_IDS) || [];
      })
      .addCase(fetchMessagesByUserId.rejected, (state) => {
        state.messages.isLoading = false;
        state.messages.isError = true;
        state.messages.list = [];
      })
      .addCase(fetchContactQuestionsByUserId.fulfilled, (state, action) => {
        const questions = action.payload;
        state.contactQuestions.isLoading = false;
        state.contactQuestions.isError = false;
        state.contactQuestions.list = Object.keys(questions).map((i) => ({
          id: i,
          question: questions[i],
        })) as IContactQuestion[];
      })
      .addCase(fetchContactQuestionsByUserId.pending, (state) => {
        state.contactQuestions.isLoading = true;
        state.contactQuestions.isError = false;
        state.contactQuestions.list = [];
      })
      .addCase(fetchContactQuestionsByUserId.rejected, (state) => {
        state.contactQuestions.isLoading = false;
        state.contactQuestions.isError = true;
        state.contactQuestions.list = [];
      })
      .addCase(fetchNewMessageAccountsByUserId.fulfilled, (state, action) => {
        state.accounts.isLoading = false;
        state.accounts.isError = false;
        state.accounts.list = action.payload || [];
      })
      .addCase(fetchNewMessageAccountsByUserId.pending, (state) => {
        state.accounts.isLoading = true;
        state.accounts.isError = false;
        state.accounts.list = [];
      })
      .addCase(fetchNewMessageAccountsByUserId.rejected, (state) => {
        state.accounts.isLoading = false;
        state.accounts.isError = true;
        state.accounts.list = [];
      })
      .addCase(newMessageSend.fulfilled, (state) => {
        state.newMessage.isLoading = false;
        state.newMessage.isError = false;
        state.newMessage.isSuccess = true;
      })
      .addCase(newMessageSend.rejected, (state) => {
        state.newMessage.isLoading = false;
        state.newMessage.isError = true;
      })
      .addCase(newMessageSend.pending, (state) => {
        state.newMessage.isLoading = true;
        state.newMessage.isError = false;
      });
  },
});
const { actions, reducer } = messagesSlice;
export const {
  deleteMessages,
  addReadMessage,
  clearSuccessMessage,
  updateReadStatus,
} = actions;
export default reducer;
