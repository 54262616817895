import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { AuthProvider } from './common/Auth';
import MVCApp from './app/configuration/MVCApp';
import initLocalization from './app/configuration/Localization';
// import reportWebVitals from './reportWebVitals';
import configuration from './app/configuration';
import msalInstance from './app/msalInstance';
import HeaderPortal from './HeaderPortal';
import FooterPortal from './FooterPortal';
import { store } from './app/store';
import SolThemeProvider from './common/components/ds/theme/SolThemeProvider';

const App = React.lazy(() => import('./App'));

const APP_BOOTSTRAP_ROOT = 'root-react-new';
const HEADER_PORTAL_ID = 'header-portal-react-new';
const FOOTER_PORTAL_ID = 'footer-portal-react-new';
const root = document.getElementById(APP_BOOTSTRAP_ROOT);
const header = document.getElementById(HEADER_PORTAL_ID);
const footer = document.getElementById(FOOTER_PORTAL_ID);

if (!configuration.IsStandalone) {
  MVCApp.setBaseUrl();
}

initLocalization(configuration);

if (root !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback="">
        <Provider store={store}>
          {configuration.IsStandalone && (
            <MsalProvider instance={msalInstance}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </MsalProvider>
          )}
          {!configuration.IsStandalone && (
            <HelmetProvider>
              <App />
            </HelmetProvider>
          )}
        </Provider>
      </Suspense>
    </React.StrictMode>,
    root
  );
}

// Temporary code for transition period with old app
if (root === null && header !== null && footer !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback="">
        <Provider store={store}>
          {configuration.IsStandalone && (
            <MsalProvider instance={msalInstance}>
              <BrowserRouter>
                <AuthProvider>
                  <SolThemeProvider
                    baseMediaUrl={configuration.DesignSystemMediaBaseUrl}
                  >
                    <HeaderPortal container={header} />
                    <FooterPortal container={footer} />
                  </SolThemeProvider>
                </AuthProvider>
              </BrowserRouter>
            </MsalProvider>
          )}
          {!configuration.IsStandalone && (
            <BrowserRouter>
              <AuthProvider>
                <SolThemeProvider
                  baseMediaUrl={configuration.DesignSystemMediaBaseUrl}
                >
                  <HeaderPortal container={header} />
                  <FooterPortal container={footer} />
                </SolThemeProvider>
              </AuthProvider>
            </BrowserRouter>
          )}
        </Provider>
      </Suspense>
    </React.StrictMode>,
    header
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
