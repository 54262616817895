import investmentPlans from '../../../../locales/en/pages/dspp/investmentPlans.json';
import companyDetails from '../../../../locales/en/pages/dspp/companyDetails.json';
import dsppVerify from '../../../../locales/en/pages/dspp/verify.json';
import taxCertCheck from '../../../../locales/en/pages/dspp/taxCertCheck.json';
import yourInvestment from '../../../../locales/en/pages/dspp/yourInvestment.json';
import payment from '../../../../locales/en/pages/dspp/payment.json';
import dsppReview from '../../../../locales/en/pages/dspp/review.json';
import summary from '../../../../locales/en/pages/dspp/summary.json';
import taxCertificationDspp from '../../../../locales/en/pages/dspp/taxCertificationDspp.json';
import taxCertificationTrustForm from '../../../../locales/en/pages/dspp/taxCertificationTrustFormData.json';

export enum DsppResourceKeys {
  investmentPlans = 'pages/dspp/investmentPlans',
  companyDetails = 'pages/dspp/companyDetails',
  verify = 'pages/dspp/verify',
  taxCertCheck = 'pages/dspp/taxCertCheck',
  yourInvestment = 'pages/dspp/yourInvestment',
  payment = 'pages/dspp/payment',
  review = 'pages/dspp/review',
  summary = 'pages/dspp/summary',
  taxCertificationDspp = 'pages/dspp/taxCertificationDspp',
  taxCertificationTrustFormData = 'pages/dspp/taxCertificationTrustFormData',
}

export type DsppResources = {
  [DsppResourceKeys.investmentPlans]: typeof investmentPlans;
  [DsppResourceKeys.companyDetails]: typeof companyDetails;
  [DsppResourceKeys.verify]: typeof dsppVerify;
  [DsppResourceKeys.taxCertCheck]: typeof taxCertCheck;
  [DsppResourceKeys.yourInvestment]: typeof yourInvestment;
  [DsppResourceKeys.payment]: typeof payment;
  [DsppResourceKeys.review]: typeof dsppReview;
  [DsppResourceKeys.summary]: typeof summary;
  [DsppResourceKeys.taxCertificationDspp]: typeof taxCertificationDspp;
  [DsppResourceKeys.taxCertificationTrustFormData]: typeof taxCertificationTrustForm;
};
