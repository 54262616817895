import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

// initialize an empty api service that we'll inject endpoints into later as needed
const emptyApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery(),
  endpoints: () => ({}),
});

export default emptyApi;
