import countryStates33 from '../../../../locales/en/countryStates/33.json';
import countryStatesL1 from '../../../../locales/en/countryStates/L1.json';

export enum CountryStatesResourceKeys {
  countryStates33 = 'countryStates/33',
  countryStatesL1 = 'countryStates/L1',
}

export type CountryStatesComponentsResources = {
  [CountryStatesResourceKeys.countryStates33]: typeof countryStates33;
  [CountryStatesResourceKeys.countryStatesL1]: typeof countryStatesL1;
};
