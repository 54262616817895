import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export const contactQuestionsSelector = (state: RootState) =>
  state.messages.contactQuestions.list;
export const contactQuestionsLoadingSelector = (state: RootState) =>
  !!state.messages.contactQuestions.isLoading;
export const contactQuestionsErrorSelector = (state: RootState) =>
  !!state.messages.contactQuestions.isError;

export const messageAccountsSelector = (state: RootState) =>
  state.messages.accounts.list;
export const messageAccountsLoadingSelector = (state: RootState) =>
  !!state.messages.accounts.isLoading;
export const messageAccountsErrorSelector = (state: RootState) =>
  !!state.messages.accounts.isError;

export const messageSendErrorSelector = (state: RootState) =>
  !!state.messages.newMessage?.isError;
export const messageSendLoadingSelector = (state: RootState) =>
  !!state.messages.newMessage?.isLoading;
export const messageSendSuccessSelector = (state: RootState) =>
  state.messages.newMessage.isSuccess;

export const allMessagesSelector = (state: RootState) =>
  state.messages.messages.list;
export const messagesLoadingSelector = (state: RootState) =>
  !!state.messages.messages.isLoading;
export const messagesErrorSelector = (state: RootState) =>
  !!state.messages.messages.isError;
export const hasMessagesSelector = (state: RootState) =>
  !!state.messages.messages.list.length;

export const unreadMessagesSelector = (state: RootState) =>
  state.messages.messages.list?.filter((i) => i.unread);

export const hasUnreadMessagesSelector = (state: RootState) =>
  !!state.messages.messages.list?.filter((i) => i.unread).length;

export const unreadSessionMessagesSelector = (state: RootState) => {
  const unreadMessages = unreadMessagesSelector(state);
  return unreadMessages?.filter(
    (msg) =>
      !state.messages.messages.markedMessagesIds?.includes(msg.messageId || 0)
  );
};

export const unreadSessionCompanyMessagesSelector = createSelector(
  unreadSessionMessagesSelector,
  (unreadSessionMessages) =>
    unreadSessionMessages?.filter((msg) => !!msg.isCompanyMessage)
);

export const unreadMessagesCountSelector = (state: RootState) =>
  state.messages.messages.list?.filter((i) => i.unread).length;

export default {
  messagesLoadingSelector,
  messagesErrorSelector,
  hasMessagesSelector,
  allMessagesSelector,
  unreadMessagesSelector,
  unreadMessagesCountSelector,
  unreadSessionMessagesSelector,

  messageAccountsSelector,
  messageAccountsLoadingSelector,
  messageAccountsErrorSelector,

  contactQuestionsSelector,
  contactQuestionsLoadingSelector,
  contactQuestionsErrorSelector,

  messageSendLoadingSelector,
  messageSendErrorSelector,
  messageSendSuccessSelector,
};
