import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import BottomBarMenuItem from './BottomBarMenuItem';
import { HeaderLink, BottomBarProps } from '../Interfaces';
import Spacings from '../../theme/common/spacings';

const BottomBar: React.FC<BottomBarProps> = ({
  menuElements,
}: BottomBarProps) => (
  <>
    <Toolbar
      sx={{
        '&.custom-toolbar': {
          padding: 0,
          alignItems: 'stretch',
          minHeight: 40,
        },
        '& button, a': {
          paddingY: 0,
          paddingX: Spacings.small2,
        },
        '& button:hover, a:hover': {
          backgroundColor: 'grey.300',
          borderRadius: '0px',
          paddingY: 0,
          paddingX: Spacings.small2,
        },
        '& button:active, a:active': {
          backgroundColor: 'grey.400',
        },
      }}
      classes={{
        root: 'custom-toolbar',
      }}
    >
      {menuElements.map((menuElem: HeaderLink, i: number) => (
        <BottomBarMenuItem
          key={menuElem.label}
          label={menuElem.label}
          url={menuElem.url}
          icon={menuElem.icon}
          subLinks={menuElem.subLinks}
          externalUrl={menuElem.externalUrl}
          index={i}
        />
      ))}
    </Toolbar>
    <Divider
      sx={{
        position: 'absolute',
        width: '100%',
        left: 0,
        borderColor: 'grey.400',
      }}
    />
  </>
);

export default BottomBar;
