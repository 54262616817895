import React from 'react';
import MuiContainer from '@mui/material/Container';
import { ContainerProps } from './Interfaces';
import Spacings from '../../theme/common/spacings';

export default ({ children, sx }: ContainerProps) => (
  <MuiContainer
    sx={{
      maxWidth: { xl: '1368px', lg: '1200px' },
      paddingX: {
        xs: Spacings.small3,
        sm: Spacings.small4,
        md: Spacings.medium2,
      },
      ...(sx || {}),
    }}
  >
    {children}
  </MuiContainer>
);
