import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import { MobileMenuItemProps } from '../Interfaces';
import KeyboardArrowIcon from './KeyboardArrowIcon';
import useMobileTheme from './MobileTheme';
import fonts from '../../theme/common/fonts';
import Spacings from '../../theme/common/spacings';

const SvgIcon = styled('img')(() => ({
  width: 24,
  height: 24,
  objectFit: 'contain',
}));

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  linkConfig,
  isBottomMenu,
  toggleDrawer,
  onClick,
  isOpen,
  themeVariant,
  isAccountLink,
}: MobileMenuItemProps) => {
  const th = useMobileTheme(themeVariant);
  const location = useLocation();
  const { icon, iconUrl, url, subLinks, label, externalUrl, disabled } =
    linkConfig;
  const DrawIcon = icon;

  const isLinkActive = url
    ? location.pathname.indexOf(url) >= 0
    : subLinks?.some(
        (link) => location.pathname.indexOf(link.url as string) >= 0
      );

  const highlight = isOpen || isLinkActive;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(e);
    if (url) toggleDrawer();
  };
  const handleClose = () => {
    toggleDrawer();
  };

  return (
    <>
      <ListItem
        button
        {...{
          href: externalUrl ? url : undefined,
          to: !externalUrl ? url : undefined,
          component: externalUrl || subLinks ? 'a' : Link,
        }}
        onClick={handleClick}
        aria-controls="mobile-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disabled={disabled}
        sx={{
          borderLeft: highlight ? `4px solid ${th.itemLeftBorder}` : 'none',
          backgroundColor: highlight ? th.item : th.backgroundColor,
          '&:hover': {
            backgroundColor: highlight ? th.item : th.backgroundColor,
          },
        }}
      >
        <ListItemText
          sx={{
            marginLeft: isOpen ? '20px' : Spacings.small4,
            marginTop: Spacings.small2,
            marginBottom: Spacings.small2,
          }}
          primary={
            <Box
              sx={{
                fontFamily: isBottomMenu
                  ? 'Avenir LT W01_65 Medium1475532'
                  : 'Avenir LT W01_85 Heavy1475544',
                letterSpacing: '-0.2px',
                display: 'flex',
                justifyContent: isBottomMenu ? 'normal' : 'space-between',
              }}
            >
              {icon && (
                <ListItemIcon>
                  <DrawIcon />
                </ListItemIcon>
              )}
              {iconUrl && (
                <ListItemIcon
                  sx={{ '&.custom-icon': { minWidth: 40 } }}
                  classes={{ root: 'custom-icon' }}
                >
                  <SvgIcon src={iconUrl} alt={label} />
                </ListItemIcon>
              )}
              <Typography
                variant="h6Light"
                sx={isAccountLink ? { fontFamily: fonts.Roman } : {}}
              >
                {label}{' '}
              </Typography>
              {subLinks?.length && (
                <KeyboardArrowIcon
                  isOpen={isOpen}
                  style={{
                    width: 24,
                    marginRight: 24,
                    filter: th.iconFilter,
                  }}
                />
              )}
            </Box>
          }
        />
      </ListItem>
      {subLinks?.length &&
        isOpen &&
        subLinks.map((link, i) => (
          <ListItem
            sx={{
              borderLeft: `4px solid ${th.itemLeftBorder}`,
              backgroundColor: th.item,
              paddingLeft: Spacings.small4,
              paddingTop: Spacings.small2,
              paddingBottom:
                i === subLinks.length - 1 ? Spacings.small3 : Spacings.small2,
              marginBottom: i === subLinks.length - 1 ? Spacings.small2 : 0,
              '&:hover': {
                backgroundColor: th.item,
              },
            }}
            classes={{ container: 'sub-item-margin' }}
            button
            {...{
              href: link.externalUrl ? link.url : undefined,
              to: !link.externalUrl ? link.url : undefined,
              component: link.externalUrl ? 'a' : Link,
            }}
            key={link.label}
            onClick={handleClose}
            aria-controls="mobile-menu"
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
          >
            <ListItemText
              sx={{
                marginLeft: Spacings.medium2,
                fontFamily: 'Avenir LT W01_65 Medium1475532',
                letterSpacing: '-0.2px',
              }}
              primary={
                <Typography variant="body5Medium">{link.label}</Typography>
              }
            />
          </ListItem>
        ))}
    </>
  );
};

export default MobileMenuItem;
