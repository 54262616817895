import {
  configureStore,
  combineReducers,
  PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import alertsReducer from '../common/slices/alerts/alertsSlice';
import { sessionStoreMiddleware } from '../common/services/sessionService/SessionService';
import addressFormatReducer from '../common/slices/addressFormat/addressFormatSlice';
import messagesReducer from '../common/slices/messages/messagesSlice';
import portfolioPageReducer from '../pages/portfolio/portfolioSlice';
import messagesPageReducer from '../pages/messages/messagesPageSlice';
import accountManagementPageReducer from '../common/slices/accountManagement/accountManagementSlice';
import messageDetailReducer from '../common/slices/messages/messageDetailSlice';
import accountsCompaniesReducer from '../common/slices/accountsInformation/companies/accountsCompaniesSlice';
import agreementsReducer from '../common/slices/agreements/agreementsSlice';
import registrationReducer from '../common/slices/registration/registrationSlice';
import userReducer from '../common/slices/users/userSlice';
import emptyApi from '../common/slices/emptyApi';

const pagesReducer = combineReducers({
  portfolio: portfolioPageReducer,
  messages: messagesPageReducer,
  accountManagement: accountManagementPageReducer,
});

const rootReducer = combineReducers({
  alerts: alertsReducer,
  messages: messagesReducer,
  messageDetails: messageDetailReducer,
  accountsCompanies: accountsCompaniesReducer,
  registration: registrationReducer,
  [emptyApi.reducerPath]: emptyApi.reducer,
  pages: pagesReducer,
  agreements: agreementsReducer,
  addressFormat: addressFormatReducer,
  user: userReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'meta.arg',
          'meta.baseQueryMeta',
          'payload.timestamp',
        ],
      },
    }).concat(sessionStoreMiddleware, emptyApi.middleware),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  if (JSON.stringify(preloadedState) !== '{}') {
    return configureStore({
      reducer: rootReducer,
      preloadedState,
    });
  }
  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
