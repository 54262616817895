import * as React from 'react';
import { styled, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { HeaderLink } from '../Interfaces';
import KeyboardArrowIcon from './KeyboardArrowIcon';
import Spacings from '../../theme/common/spacings';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[900],
  fontSize: 14,
  textTransform: 'none',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  paddingTop: 0,
  paddingBottom: 0,
}));

const BottomBarMenuItem = ({
  index,
  label,
  url,
  externalUrl,
  subLinks,
  onClick,
}: HeaderLink) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        id={`basic-button-${index}`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (onClick) onClick(e);
          handleClick(e);
        }}
        {...{
          href: externalUrl ? url : undefined,
          to: !externalUrl ? url : undefined,
          component: externalUrl || subLinks ? 'a' : Link,
        }}
        theme={theme}
        disableTouchRipple
        disableFocusRipple
        sx={{
          marginRight: Spacings.small3,
          fontFamily: 'Avenir LT W01_85 Heavy1475544',
          letterSpacing: 0,
          justifyContent: 'center',
        }}
      >
        <Box
          component="span"
          sx={{
            display: 'flex',
            lineHeight: 1.5,
            overflow: 'auto',
            '&>img': { paddingLeft: Spacings.small },
          }}
        >
          <span>{label}</span>{' '}
          {subLinks?.length && <KeyboardArrowIcon isOpen={!!anchorEl} />}
        </Box>
      </StyledButton>
      {subLinks?.length && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .bottom-bar-menu': {
              borderRadius: 0,
              minWidth: '176px',
              boxShadow: `0px 5px 5px -3px #e4e5ea, 0px 8px 10px 1px #e4e5ea, 0px 3px 14px 2px #e4e5ea`,
            },
          }}
          classes={{
            paper: 'bottom-bar-menu',
          }}
        >
          {subLinks.map((link) => (
            <MenuItem
              key={link.label}
              {...{
                href: link.externalUrl ? link.url : undefined,
                to: !link.externalUrl ? link.url : undefined,
                component: link.externalUrl ? 'a' : Link,
              }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (link.onClick) link.onClick(e);
                handleClose();
              }}
              disableTouchRipple
              sx={{
                fontSize: 14,
                letterSpacing: 0,
                paddingY: Spacings.small2,
                paddingX: Spacings.small3,
                '&:active.MuiMenuItem-root': {
                  backgroundColor: theme.palette.grey[400],
                },
                '&:hover': {
                  backgroundColor: theme.palette.grey[300],
                },
              }}
            >
              {link.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default BottomBarMenuItem;
