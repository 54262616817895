import RuntimeConfigurationProvider from './configuration/RuntimeConfigurationProvider';
import environmentConfigurationProvider from './configuration/environmentConfigurationProvider';
import globalVarsConfigurationProvider from './configuration/globalVarsConfigurationProvider';
import msalConfigurationProvider from './configuration/msalConfigurationProvider';
import { AppConfigurationProvider } from './configuration/Interfaces';

RuntimeConfigurationProvider.configurationProviders = [
  environmentConfigurationProvider,
  globalVarsConfigurationProvider,
  msalConfigurationProvider,
];
RuntimeConfigurationProvider.buildConfiguration();

const configurationProvider: AppConfigurationProvider =
  new RuntimeConfigurationProvider();

export default configurationProvider.getConfiguration();
