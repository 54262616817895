import onlineRegistration from '../../../../locales/en/pages/onlineRegistration.json';
import RedirectDialog from '../../../../locales/en/components/redirectDialog.json';

export enum RegistrationOnlineResourceKeys {
  onlineRegistration = 'pages/onlineRegistration',
  redirectDialog = 'components/redirectDialog',
}

export type RegistrationOnlineResources = {
  [RegistrationOnlineResourceKeys.onlineRegistration]: typeof onlineRegistration;
  [RegistrationOnlineResourceKeys.redirectDialog]: typeof RedirectDialog;
};
