import React, { createContext, useContext } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus as ProgressStatus } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { RedirectRequest } from '@azure/msal-browser/dist/request/RedirectRequest';
import { AuthenticationScheme } from '@azure/msal-browser/dist';
import { useTranslation } from 'react-i18next';
import RuntimeConfigurationProvider from '../app/configuration/RuntimeConfigurationProvider';
import MVCApp from '../app/configuration/MVCApp';
import { Configuration } from '../app/configuration/Interfaces';

export { InteractionStatus as ProgressStatus } from '@azure/msal-browser/dist/utils/BrowserConstants';

export type QueryParameters = {
  [key: string]: string;
};

export type AuthContextType = {
  signIn: () => void;
  signOut: () => void;
  signUp: (queryParameters?: QueryParameters) => void;
  profileSettings: () => void;
  isAuthenticated: boolean;
  isPerusal: boolean;
  progressStatus: ProgressStatus;
  account?: Account;
  allowESPPManagement: boolean;
};

type Account = {
  localAccountId: string;
  username: string;
};

const AuthContext = createContext<AuthContextType>({
  signIn: () => {
    // sign in logic is set up by provider
  },
  signOut: () => {
    // sign out logic is set up by provider
  },
  signUp: () => {
    // sign out logic is set up by provider
  },
  profileSettings: () => {
    // profile settings logic is set up by provider
  },
  isAuthenticated: false,
  isPerusal: false,
  allowESPPManagement: false,
  progressStatus: ProgressStatus.None,
});

export const useAuth = () => useContext(AuthContext);

const getMsalAuthContext = (configuration: Configuration): AuthContextType => {
  const { instance, inProgress, accounts } = useMsal();
  return {
    signIn: () => instance.loginRedirect(),
    signOut: () => instance.logoutRedirect(),
    signUp: (queryParameters?: QueryParameters) =>
      instance.loginRedirect({
        authenticationScheme: AuthenticationScheme.BEARER,
        authority: configuration?.Adb2c?.authorityRegister,
        extraQueryParameters: queryParameters,
      } as RedirectRequest),
    profileSettings: () =>
      instance.loginRedirect({
        authenticationScheme: AuthenticationScheme.BEARER,
        authority: configuration?.Adb2c?.authoritySettings,
        redirectUri: configuration?.Adb2c?.settingsRedirectUrl,
      } as RedirectRequest),
    isAuthenticated: useIsAuthenticated(),
    isPerusal: false,
    allowESPPManagement: false,
    progressStatus: inProgress,
    account: accounts[0],
  };
};

const getMvcAuthContext = (): AuthContextType => {
  const { t } = useTranslation();
  return {
    signIn: () => {
      window.location.href = MVCApp.AuthenticatedBase;
    },
    signOut: () => {
      window.location.href = t('logoutUrl');
    },
    signUp: (queryParameters?: QueryParameters) => {
      const params = new URLSearchParams(queryParameters);
      window.location.href = `${t('signupUrl')}?${params.toString()}`;
    },
    profileSettings: () => {
      window.location.href = t('profileSettingsUrl');
    },
    isAuthenticated: (window as any).isAuthenticated,
    isPerusal: (window as any).isPerusalUser,
    allowESPPManagement: (window as any).allowESPPManagement,
    progressStatus: ProgressStatus.None,
    account: {
      localAccountId: (window as any).userId,
      username: (window as any).username,
    },
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const configuration = new RuntimeConfigurationProvider().getConfiguration();

  const authContext = configuration.IsStandalone
    ? getMsalAuthContext(configuration)
    : getMvcAuthContext();

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
