import { SxProps, Theme } from '@mui/system';
import fonts from '../../theme/common/fonts';
import Spacings from '../../theme/common/spacings';

const column: (theme: Theme) => SxProps = (theme) => ({
  color: 'grey.600',
  fontSize: 12,
  fontFamily: fonts.Black,
  letterSpacing: '2.6px',
  fontWeight: '900',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.33',
  marginY: Spacings.small3,
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    marginTop: Spacings.medium,
  },
});

const item: SxProps = {
  color: 'grey.100',
  fontSize: 14,
  marginTop: { xs: Spacings.small3, sm: Spacings.small2 },
  marginBottom: { xs: Spacings.small3, sm: 0 },
  lineHeight: '1.43',
  textAlign: { xs: 'center', sm: 'left' },
};

const link = {
  color: 'grey.100',
};

export default {
  column,
  item,
  link,
};
