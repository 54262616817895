import React from 'react';
import { useTranslation } from 'react-i18next';
import { decorateWithLocalMediaBaseUrl } from '../../../app/configuration/Localization';
import BpHeaderContent from '../Header/companies/BpHeaderContent';
import ComcastHeaderContent from '../Header/companies/ComcastHeaderContent';
import { CompaniesItems } from '../Header/Interfaces';
import styles from './Companies.styles';

export default function CompaniesComponentsFactory(path: string) {
  const { t } = useTranslation();
  const companiesData = t('companies', {
    returnObjects: true,
  }) as CompaniesItems;

  let company = path;
  if (company.toLowerCase().startsWith('/bp')) {
    company = 'bp';
  } else if (company.toLowerCase().startsWith('/comcast')) {
    company = 'comcast';
  } else {
    company = '';
  }

  switch (company) {
    case 'bp':
      return [
        <BpHeaderContent
          image={decorateWithLocalMediaBaseUrl(companiesData.bp.logo.image)}
          url={companiesData.bp.logo.url}
        />,
        styles.bp as any,
      ];
    case 'comcast':
      return [
        <ComcastHeaderContent
          image={decorateWithLocalMediaBaseUrl(
            companiesData.comcast.logo.image
          )}
          url={companiesData.comcast.logo.url}
        />,
        styles.comcast as any,
      ];
    default:
      return [<></>, {}];
  }
}
