import uniqBy from 'lodash/uniqBy';
import { RootState } from '../../../app/store';
import { AlertType } from '../../services/alertService/Interfaces';

export const allAlertsSelector = (state: RootState) =>
  state.alerts?.all.filter(
    (
      al // this is done to instantly know if alert is resolved. Without it, considering SOS cache, alerts are updated after some time and it's still displaying in alerts and portfolio
    ) =>
      al.type === AlertType.UndeliverableAddress
        ? !state.alerts?.alertResolved?.includes(Number(al.accountNumber))
        : al
  );
export const hasAlertsSelector = (state: RootState) =>
  !!allAlertsSelector(state).length;
export const groupedAlertsSelector = (state: RootState) =>
  uniqBy(state.alerts?.all, 'type');
export const unreadAlertsSelector = (state: RootState) => {
  const groupedAlerts = groupedAlertsSelector(state);
  return groupedAlerts
    ?.filter((al) => !state.alerts?.readItemsTypes?.includes(al.type))
    .filter((al) =>
      al.type === AlertType.UndeliverableAddress
        ? !state.alerts?.alertResolved?.includes(Number(al.accountNumber))
        : al
    );
};
export const hasUnreadAlertsSelector = (state: RootState) =>
  !!unreadAlertsSelector(state).length;
export const alertsLoadingSelector = (state: RootState) =>
  !!state.alerts?.isLoading;
