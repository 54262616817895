import * as React from 'react';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { TopBarProps } from '../Interfaces';
import KeyboardArrowIcon from './KeyboardArrowIcon';
import { useBreakpoints } from '../../utils/useBreakpoints';
import fonts from '../../theme/common/fonts';
import Spacings from '../../theme/common/spacings';

const primaryMainSvgFilter =
  'invert(10%) sepia(82%) saturate(7095%) hue-rotate(228deg) brightness(92%) contrast(90%)';

const SvgIcon = styled('img')(() => ({
  width: 20,
  height: 20,
  margin: 0,
  marginLeft: Spacings.medium4,
  objectFit: 'contain',
}));

const IconDivider: React.FC = () => (
  <Divider
    orientation="vertical"
    flexItem
    sx={{
      marginY: Spacings.small4,
      marginX: Spacings.small3,
      marginTop: Spacings.small4,
      borderColor: 'grey.500',
    }}
  />
);

const unauthorizedButtonsBase = {
  color: 'black',
  width: 120,
  lineHeight: '1.5',
};

interface CustomBadgeProps {
  invisible: boolean;
  children: any;
}
const CustomBadge: React.FC<CustomBadgeProps> = ({
  invisible,
  children,
}: CustomBadgeProps) => (
  <Badge
    invisible={invisible}
    variant="dot"
    color="error"
    classes={{ badge: 'header-badge' }}
    sx={{ '.header-badge': { top: -5, right: -5 } }}
  >
    {children}
  </Badge>
);

const TopBar: React.FC<TopBarProps> = ({
  isLoggedIn,
  accountLinks,
  messages,
  notifications,
  buttonsSettings,
  hideLoginRegister,
}: TopBarProps) => {
  const [profileAnchorEl, setprofileAnchorEl] = React.useState(null);
  const breakpoints = useBreakpoints(['smUp']);
  const theme = useTheme();

  const openProfileMenu = (event: any) =>
    setprofileAnchorEl(event.currentTarget);
  const closeProfileMenu = () => setprofileAnchorEl(null);

  const MenuIcon = ({
    externalUrl,
    url,
    hasUnread,
    icon,
  }: {
    externalUrl: boolean;
    url: string;
    hasUnread: boolean;
    icon: React.ReactNode;
  }) => (
    <>
      <IconButton
        color="inherit"
        {...{
          href: externalUrl ? url : undefined,
          to: !externalUrl ? url : undefined,
          component: externalUrl ? 'a' : Link,
        }}
        disableTouchRipple
        disableRipple
        disableFocusRipple
      >
        <CustomBadge invisible={!hasUnread}>{icon}</CustomBadge>
      </IconButton>
      {breakpoints.smUp && <IconDivider />}
    </>
  );

  if (isLoggedIn)
    return (
      <>
        {breakpoints.smUp && (
          <MenuIcon
            externalUrl={!!messages.externalUrl}
            url={messages.url}
            hasUnread={messages.hasMessages}
            icon={<MailOutlineIcon data-testid="MessagesOutlinedIcon" />}
          />
        )}
        <MenuIcon
          externalUrl={!!notifications.externalUrl}
          url={notifications.url}
          hasUnread={notifications.hasNotifications}
          icon={<NotificationsNone data-testid="NotificationsOutlinedIcon" />}
        />
        {breakpoints.smUp && (
          <>
            <Button
              onClick={openProfileMenu}
              aria-haspopup="true"
              aria-expanded={profileAnchorEl ? 'true' : undefined}
              sx={{
                fontFamily: fonts.Heavy,
                letterSpacing: 0,
                fontSize: 14,
                '.arrow-icon': {
                  marginLeft: Spacings.small,
                  paddingLeft: 0,
                },
                '.profile-icon': { marginRight: Spacings.small },
                color: profileAnchorEl ? 'primary.main' : 'black',
                '&> img': {
                  filter: profileAnchorEl ? primaryMainSvgFilter : 'none',
                },
                '&:hover': {
                  backgroundColor: 'grey.200',
                  borderRadius: Spacings.small,
                },
              }}
              disableTouchRipple
              disableFocusRipple
            >
              <SvgIcon
                className="profile-icon"
                src={`${theme.baseMediaUrl}/v1/icon-small-user.svg`}
                alt="Account"
              />
              My Account
              <KeyboardArrowIcon
                className="arrow-icon"
                isOpen={!!profileAnchorEl}
              />
            </Button>
          </>
        )}
        {breakpoints.smUp && (
          <Menu
            anchorEl={profileAnchorEl}
            open={!!profileAnchorEl}
            onClose={closeProfileMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .top-bar-menu': {
                borderRadius: '0px',
                minWidth: '228px',
                boxShadow: `0px 5px 5px -3px #e4e5ea, 0px 8px 10px 1px #e4e5ea, 0px 3px 14px 2px #e4e5ea`,
              },
            }}
            classes={{
              paper: 'top-bar-menu',
            }}
          >
            {accountLinks?.map((link) => (
              <MenuItem
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (link.onClick) link.onClick(e);
                  closeProfileMenu();
                }}
                {...{
                  href: link.externalUrl ? link.url : undefined,
                  to: !link.externalUrl ? link.url : undefined,
                  component: link.externalUrl ? 'a' : Link,
                }}
                key={link.label}
                sx={{
                  justifyContent: 'space-between',
                  fontSize: 14,
                  fontFamily: 'Avenir LT W01_55 Roman1475520',
                  letterSpacing: 0,
                  '&:active': {
                    backgroundColor: 'grey.400',
                  },
                }}
                disabled={link.disabled}
                disableTouchRipple
              >
                {link.label}{' '}
                {link.iconUrl && (
                  <SvgIcon src={link.iconUrl} alt={link.label} />
                )}
                {link.icon && <link.icon />}
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    );

  return !hideLoginRegister
    ? (breakpoints.smUp && (
        <>
          <Button
            href={buttonsSettings.registerLink}
            onClick={(e) =>
              buttonsSettings.onRegisterClick &&
              buttonsSettings.onRegisterClick(e)
            }
            variant="outlined"
            sx={{
              ...unauthorizedButtonsBase,
              color: 'primary.main',
            }}
            data-testid="register-button"
            id="registerButton"
          >
            {buttonsSettings.registerButtonName}
          </Button>
          <Button
            href={buttonsSettings.loginLink}
            onClick={(e) =>
              buttonsSettings.onLoginClick && buttonsSettings.onLoginClick(e)
            }
            sx={{
              ...unauthorizedButtonsBase,
              marginLeft: Spacings.small3,
              color: 'white',
            }}
            variant="contained"
            data-testid="login-button"
            id="loginButton"
          >
            {buttonsSettings.loginButtonName}
          </Button>
        </>
      )) ||
        null
    : null;
};

export default TopBar;
