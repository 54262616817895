import React from 'react';
import { Box, Divider } from '@mui/material';
import { LogoSettings } from '../../ds/Header';
import styles from './CompaniesHeader.styles';

const BpHeaderContent = ({ url, image }: LogoSettings) => (
  <Box sx={styles.headerCotnainer}>
    <Divider sx={styles.headerDivider} orientation="vertical" flexItem />
    <a href={url}>
      <img src={image} alt="logo" />
    </a>
  </Box>
);

export default BpHeaderContent;
