import { Alert as ApiAlert } from '@equs/sp-api-client/dist';
import i18n from 'i18next';
import apiClient from '../../../app/apiClient';
import { Alert, AlertsLocalization, AlertType } from './Interfaces';

class AlertsService {
  alertsTranslations: AlertsLocalization;

  constructor() {
    this.alertsTranslations = {} as AlertsLocalization;
  }

  async getAll(): Promise<Alert[]> {
    const { alerts } = await apiClient.alerts_GetAlerts();
    await i18n.loadNamespaces('components/common/alerts');
    this.alertsTranslations = i18n.t('types', {
      ns: 'components/common/alerts',
      returnObjects: true,
    });

    return !alerts?.length ? [] : alerts.map((al) => this.buildAlert(al));
  }

  private buildAlert(apiAlert: ApiAlert): Alert {
    const alertType = apiAlert.alertType as unknown as AlertType;
    switch (alertType) {
      case AlertType.AccountReCertification:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.taxCertExpiring.action,
          description: this.alertsTranslations.taxCertExpiring.label,
        };
      case AlertType.CNotice:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.cNotice.action,
          description: this.alertsTranslations.cNotice.label,
          content: this.alertsTranslations.cNotice.content,
          moreInfoLabel: this.alertsTranslations.cNotice.moreInfoLabel,
          lessInfoLabel: this.alertsTranslations.cNotice.lessInfoLabel,
        };
      case AlertType.FirstBNotice:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.firstBNotice.action,
          description: this.alertsTranslations.firstBNotice.label,
        };
      case AlertType.OutstandingChecks:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.outstandingChecks.action,
          description: this.alertsTranslations.outstandingChecks.label,
        };
      case AlertType.SecondBNotice:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.secondBNotice.action,
          description: this.alertsTranslations.secondBNotice.label,
          content: this.alertsTranslations.secondBNotice.content,
          moreInfoLabel: this.alertsTranslations.secondBNotice.moreInfoLabel,
          lessInfoLabel: this.alertsTranslations.secondBNotice.lessInfoLabel,
        };
      case AlertType.TaxCertified:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.taxIdUncertified.action,
          description: this.alertsTranslations.taxIdUncertified.label,
        };
      case AlertType.UndeliverableAddress:
        return {
          type: alertType,
          accountName: apiAlert.accountName,
          accountNumber: apiAlert.accountNumber,
          action: this.alertsTranslations.undeliverableAddress.action,
          description: this.alertsTranslations.undeliverableAddress.label,
        };
      default:
        return {} as Alert;
    }
  }
}

export default AlertsService;
