const MARKED_MESSAGES_IDS = 'markedMessagesIds';
const ADD_READ_MESSAGE = 'messages/addReadMessage';
const MESSAGES_LOAD = 'messages/load';
const MESSAGES = 'messages';
const CONTACT_QUESTIONS = 'messages/contact-us/contact-questions';
const PRIVACY_INFORMATION_REQUEST = 'Privacy Information Request';
const MESSAGES_ACCOUNTS = 'messages/contact-us/accounts';
const MESSAGE_NEW = 'messages/contact-us/new-message';
export {
  MARKED_MESSAGES_IDS,
  MESSAGES_LOAD,
  MESSAGES,
  ADD_READ_MESSAGE,
  PRIVACY_INFORMATION_REQUEST,
  CONTACT_QUESTIONS,
  MESSAGES_ACCOUNTS,
  MESSAGE_NEW,
};
