enum Spacings {
  zero,
  small,
  small2,
  small3,
  small4,
  medium,
  medium2,
  medium3,
  medium4,
  large,
  large2,
  large3,
}

export default Spacings;
