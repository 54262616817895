/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type SliceState = {
  selectedIds: number[];
  filters: {
    unread: boolean;
  };
};
const initialState: SliceState = {
  selectedIds: [],
  filters: {
    unread: false,
  },
};

const messagesPageSlice = createSlice({
  name: 'messages-page',
  initialState,
  reducers: {
    setSelectedId(state, action) {
      const { id, value } = action.payload;
      let index = state.selectedIds.indexOf(id);
      if (value && index < 0) {
        state.selectedIds.push(id);
      }
      if (!value && index >= 0) {
        do {
          state.selectedIds.splice(index, 1);
          index = state.selectedIds.indexOf(id);
        } while (index > -1);
      }
    },
    setSelectedIds(state, action) {
      state.selectedIds = action.payload;
    },
    setUnreadFilter(state, action) {
      state.filters.unread = action.payload;
    },
  },
});
const { actions, reducer } = messagesPageSlice;
export const { setSelectedId, setSelectedIds, setUnreadFilter } = actions;
export default reducer;
