import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidateErrorResponse } from '../interfaces/Errors';

const createApiThunk = <TRequest, TRespone>(
  url: string,
  apiCall: (request: TRequest) => Promise<TRespone>
) =>
  createAsyncThunk(url, async (request: TRequest, { rejectWithValue }) => {
    try {
      const response = await apiCall(request);
      return response;
    } catch (err) {
      return rejectWithValue(err as ValidateErrorResponse);
    }
  });

export default createApiThunk;
