import { useTheme } from '@mui/material';
import { ThemeVariant } from '../Interfaces';

const useMobileTheme = (themeVariant: ThemeVariant) => {
  const theme = useTheme();

  const mobileTheme = {
    [ThemeVariant.dark]: {
      text: 'white',
      divider: '#4f4999',
      backgroundColor: theme.palette.insightful.main,
      item: '#494395',
      itemLeftBorder: 'white',
      iconFilter: 'brightness(0) invert(1)',
    },
    [ThemeVariant.light]: {
      text: 'black',
      divider: '#dcdee3',
      backgroundColor: 'white',
      item: theme.palette.grey[200],
      itemLeftBorder: theme.palette.red.main,
      iconFilter: '',
    },
  };

  return mobileTheme[themeVariant];
};

export default useMobileTheme;
