import {
  AppConfigurationProvider,
  Configuration,
  ConfigurationProvider,
} from './Interfaces';

export default class RuntimeConfigurationProvider
  implements AppConfigurationProvider
{
  static currentConfiguration: Configuration;

  static configurationProviders: ConfigurationProvider<Configuration>[] = [];

  static buildConfiguration = () => {
    if (RuntimeConfigurationProvider.currentConfiguration) {
      throw new Error('Configuration already initialized');
    }
    RuntimeConfigurationProvider.currentConfiguration =
      RuntimeConfigurationProvider.configurationProviders.reduce(
        (configuration, provider) => provider(configuration),
        {} as Configuration
      );
  };

  getConfiguration = () => RuntimeConfigurationProvider.currentConfiguration;
}
