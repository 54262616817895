import Spacings from '../theme/common/spacings';

const background = {
  bgcolor: '#2C384B', // TODO: adjust when new grey colors will be added.
  paddingY: Spacings.medium2,
};

const navigation = {
  display: 'grid',
  gridAutoFlow: { sm: 'column' },
  columnGap: { sm: Spacings.small4, md: Spacings.medium2 },
  justifyItems: { sm: 'center', md: 'normal' },
  marginBottom: {
    xs: Spacings.medium4,
    sm: Spacings.medium2,
    md: 0,
  },
};

export default {
  background,
  navigation,
};
