import buyWizard from '../../../../locales/en/pages/buy/buyWizard.json';
import buyCompanyDetails from '../../../../locales/en/pages/buy/companyDetails.json';
import buyInvestmentPlans from '../../../../locales/en/pages/buy/investmentPlans.json';
import buyReview from '../../../../locales/en/pages/buy/review.json';
import buySelectAccount from '../../../../locales/en/pages/buy/selectAccount.json';
import buyTexVerification from '../../../../locales/en/pages/buy/taxVerification.json';
import taxCertificationBuy from '../../../../locales/en/pages/buy/taxCertificationBuy.json';
import registrationSummary from '../../../../locales/en/pages/buy/registrationSummary.json';
import investmentDetails from '../../../../locales/en/pages/buy/investmentDetails.json';
import investmentEdit from '../../../../locales/en/pages/buy/investmentEdit.json';
import paymentDetails from '../../../../locales/en/pages/buy/paymentDetails.json';
import buyRegistrationDetails from '../../../../locales/en/pages/buy/registrationDetails.json';
import eDeliveryPreferences from '../../../../locales/en/pages/buy/eDeliveryPreferences.json';
import buySummary from '../../../../locales/en/pages/buy/summary.json';

export enum BuyResourceKeys {
  summary = 'pages/buy/summary',
  companyDetails = 'pages/buy/companyDetails',
  investmentPlans = 'pages/buy/investmentPlans',
  review = 'pages/buy/review',
  buyWizard = 'pages/buy/buyWizard',
  selectAccount = 'pages/buy/selectAccount',
  taxVerification = 'pages/buy/taxVerification',
  taxCertificationBuy = 'pages/buy/taxCertificationBuy',
  registrationSummary = 'pages/buy/registrationSummary',
  investmentDetails = 'pages/buy/investmentDetails',
  investmentEdit = 'pages/buy/investmentEdit',
  paymentDetails = 'pages/buy/paymentDetails',
  registrationDetails = 'pages/buy/registrationDetails',
  eDeliveryPreferences = 'pages/buy/eDeliveryPreferences',
}

export type BuyResources = {
  [BuyResourceKeys.summary]: typeof buySummary;
  [BuyResourceKeys.companyDetails]: typeof buyCompanyDetails;
  [BuyResourceKeys.investmentPlans]: typeof buyInvestmentPlans;
  [BuyResourceKeys.review]: typeof buyReview;
  [BuyResourceKeys.buyWizard]: typeof buyWizard;
  [BuyResourceKeys.selectAccount]: typeof buySelectAccount;
  [BuyResourceKeys.taxVerification]: typeof buyTexVerification;
  [BuyResourceKeys.taxCertificationBuy]: typeof taxCertificationBuy;
  [BuyResourceKeys.registrationSummary]: typeof registrationSummary;
  [BuyResourceKeys.investmentDetails]: typeof investmentDetails;
  [BuyResourceKeys.investmentEdit]: typeof investmentEdit;
  [BuyResourceKeys.paymentDetails]: typeof paymentDetails;
  [BuyResourceKeys.registrationDetails]: typeof buyRegistrationDetails;
  [BuyResourceKeys.eDeliveryPreferences]: typeof eDeliveryPreferences;
};
