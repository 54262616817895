import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ClearIcon from '@mui/icons-material/Clear';
import { HeaderLink, MobileMenuProps } from '../Interfaces';
import MobileMenuItem from './MobileMenuItem';
import useMobileTheme from './MobileTheme';
import Spacings from '../../theme/common/spacings';

const MobileMenu: React.FC<MobileMenuProps> = ({
  isDrawerOpen,
  toggleDrawer,
  menuElements,
  mobileAccountLinks,
  isLoggedIn,
  buttonsSettings,
  themeVariant,
}: MobileMenuProps) => {
  const th = useMobileTheme(themeVariant);
  const [openedItemIndex, setOpenedItemIndex] = useState<number | null>(null);
  const loggedOutAccountLinks: Array<HeaderLink> = [
    {
      label: buttonsSettings.loginButtonName,
      url: buttonsSettings.loginLink,
      onClick: buttonsSettings.onLoginClick,
      externalUrl: buttonsSettings.loginExternal,
      index: 0,
    },
    {
      label: buttonsSettings.registerButtonName,
      url: buttonsSettings.registerLink,
      onClick: buttonsSettings.onRegisterClick,
      externalUrl: buttonsSettings.registerExternal,
      index: 1,
    },
  ];

  const accountLinks = isLoggedIn ? mobileAccountLinks : loggedOutAccountLinks;

  const list = () => (
    <Box
      sx={{
        width: '100%',
        color: th.text,
        fontSize: 'bold',
      }}
      role="presentation"
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ClearIcon
          sx={{
            marginTop: Spacings.small4,
            marginRight: Spacings.small4,
            width: 25,
            height: 25,
          }}
          onClick={toggleDrawer}
        />
      </Box>
      {isLoggedIn && (
        <>
          <List sx={{ marginTop: Spacings.small4 }}>
            {menuElements.map((menuElem: HeaderLink, i: number) => (
              <MobileMenuItem
                key={menuElem.label}
                linkConfig={menuElem}
                toggleDrawer={toggleDrawer}
                isBottomMenu={false}
                onClick={() =>
                  setOpenedItemIndex(openedItemIndex === i ? null : i)
                }
                isOpen={i === openedItemIndex}
                themeVariant={themeVariant}
              />
            ))}
          </List>
          <Divider
            sx={{
              backgroundColor: th.divider,
              borderColor: th.divider,
              marginTop: Spacings.small4,
              marginBottom: Spacings.small4,
            }}
          />
        </>
      )}
      <List>
        {accountLinks.map((menuElem: HeaderLink) => (
          <MobileMenuItem
            key={menuElem.label}
            linkConfig={menuElem}
            onClick={menuElem.onClick}
            toggleDrawer={toggleDrawer}
            isBottomMenu
            isOpen={false}
            themeVariant={themeVariant}
            isAccountLink
          />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Drawer
        sx={{
          '& .full-screen-drawer': {
            width: '100%',
            backgroundColor: th.backgroundColor,
          },
        }}
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        classes={{
          paper: 'full-screen-drawer',
        }}
      >
        {list()}
      </Drawer>
      <Divider />
    </>
  );
};

export default MobileMenu;
