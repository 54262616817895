import { Configuration, ConfigurationProvider } from './Interfaces';

const environmentConfigurationProvider: ConfigurationProvider<Configuration> = (
  configuration = {}
) => {
  const IsStandalone = process.env.REACT_APP_STANDALONE === 'true';
  const LocalesBaseUrl = process.env.REACT_APP_LOCALES_BASE_URL;
  const MediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;
  const LocalesVersion = process.env.REACT_APP_LOCALES_VERSION;
  const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const DesignSystemMediaBaseUrl =
    process.env.REACT_APP_DESIGN_SYSTEM_MEDIA_BASE_URL;
  const LogosUrl = process.env.REACT_APP_LOGOS_URL;
  const UseLocalizationDebug =
    process.env.REACT_APP_LOCALIZATION_DEBUG === 'true';
  const Adb2c = {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID,
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    authorityRegister: process.env.REACT_APP_ADB2C_AUTHORITY_REGISTER,
    authoritySettings: process.env.REACT_APP_ADB2C_AUTHORITY_SETTINGS,
    knownAuthorities: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES,
    loginRedirectUrl: process.env.REACT_APP_ADB2C_REDIRECT_URI,
    logoutRedirectUrl: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI,
    settingsRedirectUrl: process.env.REACT_APP_ADB2C_SETTINGS_REDIRECT_URI,
    apiPermissions: (process.env.REACT_APP_ADB2C_API_PERMISSIONS || '').split(
      ' '
    ),
    tainaCertificationUrl: process.env.REACT_APP_TAINA_CERTIFICATION_URL,
  };

  return {
    ...configuration,
    IsStandalone,
    LocalesBaseUrl,
    MediaBaseUrl,
    LocalesVersion,
    ApiBaseUrl,
    DesignSystemMediaBaseUrl,
    LogosUrl,
    UseLocalizationDebug,
    Adb2c,
  };
};

export default environmentConfigurationProvider;
