import React from 'react';
import ReactDOM from 'react-dom';
import Footer from './common/components/Footer';

interface Props {
  container: HTMLElement;
}

const FooterPortal = ({ container }: Props) =>
  container ? ReactDOM.createPortal(<Footer />, container) : null;
export default FooterPortal;
