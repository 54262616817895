import bankInfo from '../../../../locales/en/components/common/bankInfo.json';
import reviewCommon from '../../../../locales/en/components/common/review.json';
import alerts from '../../../../locales/en/components/common/alerts.json';
import validations from '../../../../locales/en/components/common/validations.json';
import CancelDialog from '../../../../locales/en/components/common/cancelDialog.json';

export enum CommonComponentsResourceKeys {
  cancelDialog = 'components/common/cancelDialog',
  validations = 'components/common/validations',
  bankInfo = 'components/common/bankInfo',
  alerts = 'components/common/alerts',
  review = 'components/common/review',
}

export type CommonComponentsResources = {
  [CommonComponentsResourceKeys.cancelDialog]: typeof CancelDialog;
  [CommonComponentsResourceKeys.validations]: typeof validations;
  [CommonComponentsResourceKeys.bankInfo]: typeof bankInfo;
  [CommonComponentsResourceKeys.alerts]: typeof alerts;
  [CommonComponentsResourceKeys.review]: typeof reviewCommon;
};
