import { SxProps } from '@mui/system';
import Spacings from '../../theme/common/spacings';

const container = {
  display: 'grid',
  alignSelf: 'start',
  justifyItems: { xs: 'center', md: 'start' },
  rowGap: Spacings.small2,
};

const label: SxProps = {
  color: 'grey.100',
  fontSize: 18,
  fontWeight: '900',
  whiteSpace: { md: 'pre-line' },
};

const copyright = {
  color: 'common.white',
  opacity: 0.4,
  fontSize: 12,
};

export default {
  container,
  label,
  copyright,
};
