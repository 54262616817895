import { SxProps } from '@mui/system';

const headerCotnainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
};

const headerDivider: SxProps = {
  marginX: 4,
  border: '1px solid grey',
  borderColor: 'grey.400',
};

export default {
  headerCotnainer,
  headerDivider,
};
