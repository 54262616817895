import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FooterCopyrightPanelProps } from '../Interfaces';
import styles from './CopyrightPanel.styles';

const CopyrightPanel: React.FC<FooterCopyrightPanelProps> = ({
  logoUrl,
  label,
  copyrightText,
  logoAlt,
}: FooterCopyrightPanelProps) => (
  <Box sx={styles.container}>
    <img src={logoUrl} alt={logoAlt} />
    <Typography sx={styles.label} data-testid="footer-label">
      {label}
    </Typography>
    <Typography sx={styles.copyright}>{copyrightText}</Typography>
  </Box>
);

export default CopyrightPanel;
