/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserPortfolioSummaryResponse } from '@equs/sp-api-client';
import apiClient from '../../../../app/apiClient';

export type AccountsCompaniesSliceState = {
  isLoading: boolean;
  isError: boolean;
  portfolio: UserPortfolioSummaryResponse;
};

const initialState: AccountsCompaniesSliceState = {
  isLoading: false,
  isError: false,
  portfolio: {
    accounts: [],
    isMarketPricingAvailable: false,
    totalValue: 0,
  },
};
export const fetchPortfolioByUserId = createAsyncThunk(
  'account-Management/portfolio',
  async (request: any) => {
    const response = await apiClient.portfolio_GetPortfolio(
      request.applyZeroFilter
    );
    return response;
  }
);
export const fetchDividendsByUserId = createAsyncThunk(
  'account-Management/portfolio',
  async () => {
    const response = await apiClient.portfolio_GetDividends();
    return response;
  }
);

const accountsCompaniesSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioByUserId.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,

      portfolio: action.payload,
    }));
    builder.addCase(fetchPortfolioByUserId.rejected, (state) => ({
      ...state,
      isLoading: false,
      isError: true,
    }));
    builder.addCase(fetchPortfolioByUserId.pending, (state) => ({
      ...state,
      isLoading: true,
      isError: false,
    }));
  },
});

export default accountsCompaniesSlice.reducer;
