import { Middleware } from '@reduxjs/toolkit';
import {
  MARKED_MESSAGES_IDS,
  ADD_READ_MESSAGE,
} from '../../constants/messageConstants';
import {
  ADD_READ_ALERTS,
  MARKED_ALERTS,
  ADD_RESOLVED_ALERTS,
  MARKED_RESOLVED_ALERTS,
} from '../../constants/alertConstants';
import {
  ADD_GLOBAL_DELIVERY_PREFERENCES_TO_SESSION_STORAGE,
  GLOBAL_DELIVERY_PREFERENCES_SESSION_STORAGE_KEY,
} from '../../constants/globalDeliveryPreferencesConstants';

abstract class SessionService {
  public static handleAction(store: any, action: any) {
    switch (action.type) {
      case ADD_READ_MESSAGE: {
        const readMsgIds = store.getState().messages.messages.markedMessagesIds;
        sessionStorage.setItem(MARKED_MESSAGES_IDS, JSON.stringify(readMsgIds));
        break;
      }
      case ADD_READ_ALERTS: {
        const readAlertsTypes = store.getState().alerts.readItemsTypes;
        sessionStorage.setItem(MARKED_ALERTS, JSON.stringify(readAlertsTypes));
        break;
      }
      case ADD_RESOLVED_ALERTS: {
        const readAlertsTypes = store.getState().alerts.alertResolved;
        sessionStorage.setItem(
          MARKED_RESOLVED_ALERTS,
          JSON.stringify(readAlertsTypes)
        );
        break;
      }
      case ADD_GLOBAL_DELIVERY_PREFERENCES_TO_SESSION_STORAGE: {
        sessionStorage.setItem(
          `${GLOBAL_DELIVERY_PREFERENCES_SESSION_STORAGE_KEY}-${action.payload}`,
          JSON.stringify({ Date: new Date().toLocaleString() })
        );
        break;
      }
      default: {
        //
      }
    }
  }

  public static loadFromSession(key: string) {
    try {
      const serializedState = sessionStorage.getItem(key);

      if (serializedState === null) {
        return undefined;
      }

      return JSON.parse(serializedState);
    } catch (error) {
      return undefined;
    }
  }
}

export const sessionStoreMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    SessionService.handleAction(store, action);
    return result;
  };

export default SessionService;
