import taxCertificationForm from '../../../../locales/en/components/dsppBuy/taxCertificationForm.json';
import taxCertificationDialog from '../../../../locales/en/components/dsppBuy/taxCertificationDialog.json';
import dividendPaymentMethod from '../../../../locales/en/components/dsppBuy/dividendPaymentMethod.json';
import dividendReinvestment from '../../../../locales/en/components/dsppBuy/dividendReinvestment.json';
import companyComponents from '../../../../locales/en/components/dsppBuy/companies.json';
import investmentDetailsForms from '../../../../locales/en/components/dsppBuy/investmentDetailsForms.json';

export enum DsppBuyComponentsResourceKeys {
  dividendReinvestment = 'components/dsppBuy/dividendReinvestment',
  dividendPaymentMethod = 'components/dsppBuy/dividendPaymentMethod',
  companyComponents = 'components/dsppBuy/companies',
  investmentDetailsForms = 'components/dsppBuy/investmentDetailsForms',
  taxCertificationDialog = 'components/dsppBuy/taxCertificationDialog',
  taxCertificationForm = 'components/dsppBuy/taxCertificationForm',
}

export type DsppBuyComponentsResources = {
  [DsppBuyComponentsResourceKeys.dividendReinvestment]: typeof dividendReinvestment;
  [DsppBuyComponentsResourceKeys.dividendPaymentMethod]: typeof dividendPaymentMethod;
  [DsppBuyComponentsResourceKeys.companyComponents]: typeof companyComponents;
  [DsppBuyComponentsResourceKeys.investmentDetailsForms]: typeof investmentDetailsForms;
  [DsppBuyComponentsResourceKeys.taxCertificationDialog]: typeof taxCertificationDialog;
  [DsppBuyComponentsResourceKeys.taxCertificationForm]: typeof taxCertificationForm;
};
