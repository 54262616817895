/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../app/apiClient';

type SliceState = {
  isModalOpened: boolean;
  applyZeroBalanceFilter: boolean;
  excelFileData: string;
};
const initialState: SliceState = {
  isModalOpened: false,
  applyZeroBalanceFilter: false,
  excelFileData: '',
};

export const fetchPortfolioExcel = createAsyncThunk(
  'portfolio/exportXls',
  async (request: any) => {
    const response = await apiClient.portfolio_DownloadPortfolioExcel(
      request.applyZeroFilter
    );

    return response;
  }
);

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioModal(state, action) {
      state.isModalOpened = action.payload;
    },
    toggleFilter(state, action) {
      state.applyZeroBalanceFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioExcel.fulfilled, (state, action) => {
      state.excelFileData = action.payload.fileData!;
    });
  },
});

const { actions, reducer } = portfolioSlice;
export const { setPortfolioModal, toggleFilter } = actions;
export default reducer;
