export default class MVCApp {
  static readonly UnauthenticatedBase = '/transactional/anonymous/newsol';

  static readonly AuthenticatedBase = '/transactional/authenticated/newsol';

  static BaseUrl: string;

  static setBaseUrl() {
    if (window.location.pathname.startsWith(MVCApp.AuthenticatedBase)) {
      MVCApp.BaseUrl = MVCApp.AuthenticatedBase;
    } else {
      MVCApp.BaseUrl = MVCApp.UnauthenticatedBase;
    }
  }
}
