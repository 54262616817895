import React from 'react';
import { Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { FooterColumnPanelProps } from '../Interfaces';
import styles from './ColumnPanel.styles';

const ColumnPanel: React.FC<FooterColumnPanelProps> = ({
  label,
  links,
}: FooterColumnPanelProps) => {
  const theme: Theme = useTheme();

  return (
    <Box>
      <Typography sx={styles.column(theme)}>{label}</Typography>
      {links.map((item) => (
        <Typography key={item.caption} sx={styles.item}>
          <Link href={item.link} sx={styles.link}>
            {item.caption}
          </Link>
        </Typography>
      ))}
    </Box>
  );
};

export default ColumnPanel;
