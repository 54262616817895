import { useEffect, useState } from 'react';
import { Breakpoint, useMediaQuery } from '@mui/material';
import { SolTheme } from '../theme/SolThemeProvider';

export function useBreakpoints<T extends string>(
  boundaries: T[]
): Record<T, boolean> {
  const [list, setList] = useState<Record<T, boolean>>(
    boundaries.reduce(
      (defaultList, boundary) => ({
        ...defaultList,
        [boundary]: false,
      }),
      {} as unknown as Record<T, boolean>
    )
  );

  const breakpoints = boundaries.reduce(
    (newList, boundary) => {
      const type = boundary.substring(2) === 'Up' ? 'up' : 'down';
      const breakpoint = boundary.substr(0, 2) as Breakpoint;
      const status = useMediaQuery<SolTheme>((theme) =>
        theme.breakpoints[type](breakpoint)
      );
      return { ...newList, [boundary]: status };
    },
    { ...list }
  );

  useEffect(() => setList(breakpoints), [...Object.values(breakpoints)]);

  return list;
}

export default useBreakpoints;
