import { CSSProperties } from 'react';
import { SxProps } from '@mui/system';

const linkBase: CSSProperties & SxProps = {
  color: '#0C41C9',
  textDecoration: 'underline #0C41C950',
  '&:hover': {
    textDecorationColor: '#0C41C9',
  },
};

export default linkBase;
