import { ThemeOptions } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import linkBase from '../common/linkBase';
import fonts from '../common/fonts';
import typography from '../common/typography';

const getEqMainThemeOptions: () => ThemeOptions = () => ({
  baseMediaUrl: 'https://eqsolintusapp.blob.core.windows.net/web-resources',
  typography,
  palette: {
    common: {
      black: '#232324',
      white: '#ffffff',
    },
    primary: {
      main: '#0C41C9',
      light: '#F5F5F7',
      dark: '#19316F',
      '300': '#CBC8ED',
      '200': '#EAE9F7',
    },
    secondary: {
      main: '#016F8E',
      light: '#3CA4C1',
      dark: '#0A3848',
      '300': '#8CCCDE',
    },
    tertiary: {
      main: '#E9EBF0',
      light: '#F1F3F8',
      dark: '#C5C9D3',
      '300': '#7A929B',
    },
    info: {
      main: '#7E95BF',
      light: '#B0C1DF',
      dark: '#384A69',
      '300': '#E0E6F1',
      '200': '#F0F4FC',
    },
    warning: {
      main: '#F8C002',
      light: '#FDD85B',
      dark: '#4F4A37',
      '300': '#FAE6A3',
      '200': '#FCF6E1',
    },
    error: {
      main: '#DE0A14',
      light: '#FF8F94',
      dark: '#68292C',
      '300': '#FFD1D3',
      '200': '#FFF2F3',
    },
    green: {
      main: '#04B17E',
      light: '#56D4AF',
      dark: '#2D594C',
      '300': '#BBF2E2',
      '200': '#EDFAF6',
    },
    // to be removed after button colors fix
    companyGrey: {
      main: '#F0F1F5',
      light: '#F5F5F7',
      dark: '#E4E6ED',
    },
    grey: {
      '900': '#232324',
      '800': '#343742',
      '700': '#535661',
      '600': '#848899',
      '500': '#C2C6D1',
      '400': '#E4E6ED',
      '300': '#F0F1F5',
      '200': '#F5F5F7',
      '100': '#F7F8FA',
    },
    blue: {
      '100': '#1C1F4C',
      '200': '#19316F',
      '300': '#1042A2',
      '400': '#0C41C9',
      '500': '#0041E9',
      '600': '#2E64F0',
      '700': '#5B7FFF',
      '800': '#8DA6FF',
      '900': '#B2C3FF',
      '950': '#D8E3FF',
      '990': '#ECF1FF',
    },
    red: {
      main: '#DE0A14',
      light: '#FF8F94',
      dark: '#68292C',
      '300': '#FFD1D3',
      '200': '#FFF2F3',
    },
    insightful: {
      main: '#37308C',
      light: '#716AC7',
      dark: '#19126E',
      '300': '#CBC8ED',
      '200': '#EAE9F7',
    },
    steel: {
      main: '#016F8E',
      light: '#3CA4C1',
      dark: '#0A3848',
      '300': '#8CCCDE',
    },
    royal: {
      main: '#0A3848',
      light: '#375F6D',
      dark: '#0A3848',
      '300': '#7A929B',
    },
  },
  // prettier-ignore
  spacing: [0,
    4, 8, 16, 24, // small
    32, 40, 56, 72, // medium
    112, 128, 272], // large
  baselines: ['20px', '28px'],
  breakpoints: {
    values: {
      xs: 360, // TODO: check if we support 360 or 320 ???
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1441,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          // fontFamily: `${fonts.Roman}, Arial, Tahoma, sans-serif`,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
        '::selection': {
          backgroundColor: '#D8E3FF',
        },
        a: {
          ...linkBase,
        },
        form: {
          // fontFamily: fonts.Roman,
          '& .MuiLoadingButton-root.Mui-disabled': {
            backgroundColor: '#F0F1F5',
          },
          '& .MuiLoadingButton-loadingIndicator': {
            backgroundColor: '#F0F1F5',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              marginTop: '4px',
            },
          },
        },
      },
      styleOverrides: {
        icon: {
          width: 24,
          height: 24,
          right: 12,
        },
        select: {
          paddingRight: 37,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDownIcon />,
      },
    },
    MuiButton: {
      // TODO: switch to ButtonBase?
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: fonts.Heavy,
          borderRadius: 4,
          textTransform: 'none',
          fontSize: 15,
          '&.MuiButton-containedPrimary': {
            color: 'white',
          },
          '&.MuiButton-containedTertiary': {
            color: '#0C41C9',
          },
          '&.MuiButton-textPrimary': {
            // color: '#0C41C9', this change breaks a lot of SOL hardcoded styles...
            '&:hover': {
              backgroundColor: '#F3F4F8',
            },
            '&.Mui-disabled': {
              background: 'none',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#F3F4F8',
            color: '#A1A8B4',
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: 24,
          width: 40,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                backgroundColor: '#0041E9',
                opacity: 1,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: '#C5C9D3',
              },
              '& .MuiSwitch-thumb': {
                borderColor: '#0041E9',
                '&:before': {
                  display: 'block',
                  content: "''",
                  width: 24,
                  height: 24,
                  backgroundSize: 16,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#0041E9'
                  )}" d="M9 16.17 5.53 12.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41a.9959.9959 0 0 0-1.41 0L9 16.17z"/></svg>')`,
                },
              },
            },
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                backgroundColor: '#C5C9D3',
                opacity: 1,
              },
              '& .MuiSwitch-thumb': {
                borderColor: '#C5C9D3',
              },
              '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#C5C9D3'
                  )}" d="M9 16.17 5.53 12.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41a.9959.9959 0 0 0-1.41 0L9 16.17z"/></svg>')`,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              color: '#F8F9FD',
              width: 24,
              height: 24,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: '#B2C3FF',
            },
          },
          '& .MuiSwitch-track': {
            borderRadius: '12px',
            backgroundColor: '#B2C3FF',
            opacity: 1,
          },

          '&.MuiSwitch-sizeSmall': {
            height: 20,
            width: 32,
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                '& .MuiSwitch-thumb:before': {
                  width: 20,
                  height: 20,
                  backgroundSize: 12,
                },
              },
              '& .MuiSwitch-thumb': {
                width: 20,
                height: 20,
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: '10px',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
            // color: '#C2C6D1', // This must be set up just for single MuiIcon (CheckBoxOutlineBlankIcon)
          },
          '&.Mui-checked .MuiSvgIcon-root': {
            color: 'inherit',
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          paddingTop: '9px',
          paddingBottom: '16px',
          fontSize: 16,
          '& .MuiInputBase-root': {
            borderRadius: 4,
            font: 'inherit',
            fontSize: 'inherit',
            letterSpacing: '-0.2px',
            // fontFamily: fonts.Medium, // original but looks differently in html and in designs
            // fontFamily: fonts.Light, // temp replacement for above issue
            fontWeight: 'bold', // temp replacement for above issue
            '& fieldset': {
              borderColor: '#C2C6D1',
            },
          },
          '& .MuiInputLabel-root': {
            top: 'unset',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            color: '#848899',
          },
        },
      },
    },
  },
});

export default getEqMainThemeOptions;
