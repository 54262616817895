import { SxProps } from '@mui/system';
import { SolTheme } from '../ds/theme/SolThemeProvider';

const bp: SxProps<SolTheme> = {
  '& #registerButton': {
    color: (theme) => theme.external.bp.palette.primary.main,
    borderColor: (theme) => theme.external.bp.palette.primary.main,
    '&:hover': {
      borderColor: (theme) => theme.external.bp.palette.primary.main,
    },
  },
  '& #loginButton': {
    color: 'white',
    backgroundColor: (theme) => theme.external.bp.palette.primary.main,
    borderColor: (theme) => theme.external.bp.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'white',
      color: (theme) => theme.external.bp.palette.primary.main,
    },
  },
};

const comcast: SxProps<SolTheme> = {
  '& #registerButton': {
    color: (theme) => theme.external.comcast.palette.primary.main,
    borderColor: (theme) => theme.external.comcast.palette.primary.main,
    '&:hover': {
      borderColor: (theme) => theme.external.comcast.palette.primary.main,
    },
  },
  '& #loginButton': {
    color: 'white',
    backgroundColor: (theme) => theme.external.comcast.palette.primary.main,
    borderColor: (theme) => theme.external.comcast.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'white',
      color: (theme) => theme.external.comcast.palette.primary.main,
    },
  },
};

export default {
  bp,
  comcast,
};
