/* eslint-disable no-param-reassign */
import { Attachment } from '@equs/sp-api-client/dist';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */
import apiClient from '../../../app/apiClient';

type SliceState = {
  attachments?: Attachment[];
  isError: boolean;
  isLoading: boolean;
  isReplyMessageSuccess: boolean;
  isDeleteMessageSuccess: boolean;
};

const initialState: SliceState = {
  attachments: [],
  isError: false,
  isLoading: false,
  isReplyMessageSuccess: false,
  isDeleteMessageSuccess: false,
};

export const messageDelete = createAsyncThunk(
  'messages/delete',
  async (request: any) => {
    try {
      await apiClient.message_DeleteMessages(request.messageDeleteRequest);
      return true;
    } catch (error) {
      return false;
    }
  }
);
export const messageReply = createAsyncThunk(
  'messages/reply',
  async (request: any) => {
    try {
      await apiClient.message_ActionReplyToMessage(
        request.messageId,
        request.messageBody
      );
      return true;
    } catch (error) {
      return false;
    }
  }
);
export const messageUpdateUnread = createAsyncThunk(
  'messages/update',
  async (request: any) => {
    try {
      await apiClient.message_UpdateReadStatus(request.updateReadStatusRequest);
      return true;
    } catch (error) {
      return false;
    }
  }
);
export const messageAttachements = createAsyncThunk(
  'messages/attachments',
  async (request: any) => {
    const response = await apiClient.message_GetAttachments(request.messageId);
    return response.attachments;
  }
);
const messageDetailSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearDetailSuccessMessage: (state, action) => {
      state.isDeleteMessageSuccess = action.payload;
      state.isReplyMessageSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(messageDelete.fulfilled, (state) => {
        state.isError = false;
        state.isLoading = false;
        state.isDeleteMessageSuccess = true;
      })
      .addCase(messageDelete.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(messageDelete.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(messageReply.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(messageReply.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(messageReply.fulfilled, (state) => {
        state.isError = false;
        state.isLoading = false;
        state.isReplyMessageSuccess = true;
      })
      .addCase(messageUpdateUnread.fulfilled, (state) => {
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(messageUpdateUnread.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(messageUpdateUnread.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(messageAttachements.fulfilled, (state, action) => {
        state.attachments = action.payload || [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(messageAttachements.rejected, (state) => {
        state.attachments = [];
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(messageAttachements.pending, (state) => {
        state.attachments = [];
        state.isLoading = true;
        state.isError = false;
      });
  },
});
const { actions, reducer } = messageDetailSlice;
export const { clearDetailSuccessMessage } = actions;
export default reducer;
