import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import axios from 'axios';
import * as locales from 'date-fns/locale';
import { Configuration } from './Interfaces';
import common from '../../../locales/en/common.json';

import RuntimeConfigurationProvider from './RuntimeConfigurationProvider';
import {
  VerifySsnResources,
  VerifySnnResourceKeys,
} from './LocalizationResources/VerifySsnResources';
import {
  RegistrationOnlineResources,
  RegistrationOnlineResourceKeys,
} from './LocalizationResources/RegistrationOnlineResources';
import {
  AccountBalanceReportResources,
  AccountBalanceReportResourceKeys,
} from './LocalizationResources/AccountBalanceReportResources';
import {
  CountryStatesComponentsResources,
  CountryStatesResourceKeys,
} from './LocalizationResources/CountryStatesComponentsResources';
import {
  RegistrationDetailsComponentsResources,
  RegistrationDetailsComponentsResourceKeys,
} from './LocalizationResources/RegistrationDetailsComponentsResources';
import {
  DsppBuyComponentsResources,
  DsppBuyComponentsResourceKeys,
} from './LocalizationResources/DsppBuyComponentsResources';
import {
  CommonComponentsResources,
  CommonComponentsResourceKeys,
} from './LocalizationResources/CommonComponentsResources';
import {
  DsppResources,
  DsppResourceKeys,
} from './LocalizationResources/DsppResources';
import {
  BuyResources,
  BuyResourceKeys,
} from './LocalizationResources/BuyResources';
import { BasicPageResources } from './LocalizationResources/BasicPageResources';
import { MessagesComponentsResource } from './LocalizationResources/MessagesComponentsResource';
import {
  DividendResourceKeys,
  DividendResources,
} from './LocalizationResources/DividendResources';

declare module 'react-i18next' {
  interface CustomResources
    extends BasicPageResources,
      BuyResources,
      DsppResources,
      CommonComponentsResources,
      DsppBuyComponentsResources,
      RegistrationDetailsComponentsResources,
      CountryStatesComponentsResources,
      AccountBalanceReportResources,
      RegistrationOnlineResources,
      VerifySsnResources,
      MessagesComponentsResource,
      DividendResources {
    common: typeof common;
  }

  type CountryStatesKeys = Exclude<string, keyof CustomResources>;
  type CountryStatesResources = Record<
    CountryStatesKeys,
    { states: { name: string; code: string }[] }
  >;

  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: CustomResources & CountryStatesResources;
  }
}

const UpdateLocalStorageWithLatestMetadata = (configuration: Configuration) => {
  axios
    .get(`${configuration.LocalesBaseUrl}/locales-metadata.json`)
    .then((res) => {
      if (res.data) {
        localStorage.setItem('locales-metadata', JSON.stringify(res.data));
      }
    });
};

const DetermineLocalesVersionBasedOnMetadaAndConfig = (
  configuration: Configuration
) => {
  const localesJson = localStorage.getItem('locales-metadata');
  if (localesJson) {
    const metadataObject = JSON.parse(localesJson);
    const [metadataDate, metadataIncrement] = metadataObject.version.split('.');
    const [confDate, confIncrement] = (
      configuration.LocalesVersion || '0.0'
    ).split('.');
    if (metadataDate === confDate) {
      return Number(metadataIncrement) > Number(confIncrement)
        ? metadataObject.version
        : configuration.LocalesVersion;
    }
    if (metadataDate > confDate) {
      return metadataObject.version;
    }
    return configuration.LocalesVersion;
  }
  return configuration.LocalesVersion;
};

const getCurrentLocalesVersionAndUpdateLocalStorage = (
  configuration: Configuration
) => {
  UpdateLocalStorageWithLatestMetadata(configuration);
  return DetermineLocalesVersionBasedOnMetadaAndConfig(configuration);
};

const getLocalizationFilesPath = (configuration: Configuration) => {
  const base = configuration.LocalesBaseUrl;
  const version = configuration.LocalesVersion
    ? `/${getCurrentLocalesVersionAndUpdateLocalStorage(configuration)}`
    : '';

  return `${base}${version}/{{lng}}/{{ns}}.json`;
};

export const convertEnumKeysToArray = (resourceKeys: {
  [key: string]: any;
}) => {
  return Object.values(resourceKeys).filter(
    (value) => typeof value === 'string'
  );
};

export const getBuyBundleNamespaces = () => {
  const buyNamespaces = convertEnumKeysToArray(BuyResourceKeys);
  const dsppBuyComponentsNamespaces = convertEnumKeysToArray(
    DsppBuyComponentsResourceKeys
  );
  const commonComponentsNamespaces = convertEnumKeysToArray(
    CommonComponentsResourceKeys
  );
  return buyNamespaces
    .concat(dsppBuyComponentsNamespaces)
    .concat(commonComponentsNamespaces);
};

export const getDsppBundleNamespaces = () => {
  const dsppNamespaces = convertEnumKeysToArray(DsppResourceKeys);
  const dsppBuyComponentsNamespaces = convertEnumKeysToArray(
    DsppBuyComponentsResourceKeys
  );
  const commonComponentsNamespaces = convertEnumKeysToArray(
    CommonComponentsResourceKeys
  );
  const registrationDetailsComponentsNamespaces = convertEnumKeysToArray(
    RegistrationDetailsComponentsResourceKeys
  );
  const countryStatesNamespaces = convertEnumKeysToArray(
    CountryStatesResourceKeys
  );
  return dsppNamespaces
    .concat(dsppBuyComponentsNamespaces)
    .concat(commonComponentsNamespaces)
    .concat(registrationDetailsComponentsNamespaces)
    .concat(countryStatesNamespaces);
};

export const getAccountBalanceReportBundleNamespaces = () => {
  const accountBalanceReportsNamespaces = convertEnumKeysToArray(
    AccountBalanceReportResourceKeys
  );
  const commonComponentsNamespaces = convertEnumKeysToArray(
    CommonComponentsResourceKeys
  );
  return accountBalanceReportsNamespaces.concat(commonComponentsNamespaces);
};

export const getRegistrationOnlineBundleNamespaces = () => {
  const registrationOnlineNamespaces = convertEnumKeysToArray(
    RegistrationOnlineResourceKeys
  );
  const commonComponentsNamespaces = convertEnumKeysToArray(
    CommonComponentsResourceKeys
  );
  const countryStatesNamespaces = convertEnumKeysToArray(
    CountryStatesResourceKeys
  );
  return registrationOnlineNamespaces
    .concat(commonComponentsNamespaces)
    .concat(countryStatesNamespaces);
};

export const getVerifySsnBundleNamespaces = () => {
  const verifySSNNamespaces = convertEnumKeysToArray(VerifySnnResourceKeys);
  return verifySSNNamespaces;
};

export const getDividendBundleNamespaces = () => {
  const dividendNamespaces = convertEnumKeysToArray(DividendResourceKeys);
  return dividendNamespaces;
};

export const getI18nOptions = () => ({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  defaultNS: 'common',
  returnObjects: true,
  ns: 'common',
  preload: ['en'],
});

const initLocalization = (configuration: Configuration) => {
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      ...getI18nOptions(),
      debug: configuration.UseLocalizationDebug,
      backend: {
        loadPath: getLocalizationFilesPath(configuration),
      },
    });
};

export const decorateWithLocalMediaBaseUrl = (url: string) => {
  const configuration = new RuntimeConfigurationProvider().getConfiguration();
  return url.replace('$(LOCAL_MEDIA_URL)', configuration.MediaBaseUrl || '');
};

export const decorateWithDesignSystemMediaBaseUrl = (url: string) => {
  const configuration = new RuntimeConfigurationProvider().getConfiguration();
  return url.replace(
    '$(DESIGN_SYSTEM_MEDIA_URL)',
    configuration.DesignSystemMediaBaseUrl || ''
  );
};

export const userLocale: string =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : // @ts-ignore // doesn't recognise IE navigator fields
      navigator.userLanguage ||
      navigator.language ||
      // @ts-ignore // doesn't recognise IE navigator fields
      navigator.browserLanguage ||
      'en-US';

export const userLocaleObject =
  // @ts-ignore // dynamic locales loader
  locales[userLocale.replace('-', '')] ||
  // @ts-ignore // dynamic locales loader
  locales[userLocale.substring(0, 2)] ||
  locales.enUS;

export default initLocalization;
