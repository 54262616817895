import { TypographyOptions } from '@mui/material/styles/createTypography';
import fonts from './fonts';
import linkBase from './linkBase';

const bodyDefault = {
  fontFamily: fonts.Roman,
  fontSize: '16px',
};

const typography: TypographyOptions = {
  fontFamily: `${fonts.Roman}, Arial, Tahoma, sans-serif`,
  h0: {
    fontFamily: fonts.Black,
    fontSize: '60px',
  },
  h1: {
    fontFamily: fonts.Black,
    fontSize: '48px',
  },
  h2: {
    fontFamily: fonts.Black,
    fontSize: '36px',
  },
  h3: {
    fontFamily: fonts.Black,
    fontSize: '28px',
  },
  h4: {
    fontFamily: fonts.Black,
    fontSize: '22px',
  },
  h5: {
    fontFamily: fonts.Black,
    fontSize: '18px',
  },
  h6: {
    fontFamily: fonts.Black,
    fontSize: '16px',
  },
  h0Light: {
    fontFamily: fonts.Heavy,
    fontSize: '60px',
  },
  h1Light: {
    fontFamily: fonts.Heavy,
    fontSize: '48px',
  },
  h2Light: {
    fontFamily: fonts.Heavy,
    fontSize: '36px',
  },
  h3Light: {
    fontFamily: fonts.Heavy,
    fontSize: '28px',
  },
  h4Light: {
    fontFamily: fonts.Heavy,
    fontSize: '22px',
  },
  h5Light: {
    fontFamily: fonts.Heavy,
    fontSize: '18px',
  },
  h6Light: {
    fontFamily: fonts.Heavy,
    fontSize: '16px',
  },
  body1: bodyDefault, // This is used as base for other components e.g. Inputs. I was not able to override it through CssBaseline though
  body2: {
    fontFamily: fonts.Roman,
    fontSize: '28px',
  },
  body3: {
    fontFamily: fonts.Roman,
    fontSize: '22px',
  },
  body4: {
    fontFamily: fonts.Roman,
    fontSize: '18px',
  },
  body5: bodyDefault, // TODO: we can override default MUI props and setup other variant as global default
  body6: {
    fontFamily: fonts.Roman,
    fontSize: '14px',
  },
  body1Medium: {
    fontFamily: fonts.Medium,
    fontSize: '36px',
  },
  body2Medium: {
    fontFamily: fonts.Medium,
    fontSize: '28px',
  },
  body3Medium: {
    fontFamily: fonts.Medium,
    fontSize: '22px',
  },
  body4Medium: {
    fontFamily: fonts.Medium,
    fontSize: '18px',
  },
  body5Medium: {
    fontFamily: fonts.Medium,
    fontSize: '16px',
  },
  body6Medium: {
    fontFamily: fonts.Medium,
    fontSize: '14px',
  },
  body1Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '36px',
  },
  body2Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '28px',
  },
  body3Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '22px',
  },
  body4Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '18px',
  },
  body5Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '16px',
  },
  body6Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '14px',
  },
  subtitle1: {
    fontFamily: fonts.Heavy,
    fontSize: '36px',
  },
  subtitle2: {
    fontFamily: fonts.Heavy,
    fontSize: '28px',
  },
  subtitle3: {
    fontFamily: fonts.Heavy,
    fontSize: '22px',
  },
  subtitle4: {
    fontFamily: fonts.Heavy,
    fontSize: '18px',
  },
  subtitle5: {
    fontFamily: fonts.Heavy,
    fontSize: '16px',
  },
  subtitle6: {
    fontFamily: fonts.Heavy,
    fontSize: '14px',
  },
  link1: {
    fontFamily: fonts.Roman,
    fontSize: '36px',
    ...linkBase,
  },
  link2: {
    fontFamily: fonts.Roman,
    fontSize: '28px',
    ...linkBase,
  },
  link3: {
    fontFamily: fonts.Roman,
    fontSize: '22px',
    ...linkBase,
  },
  link4: {
    fontFamily: fonts.Roman,
    fontSize: '18px',
    ...linkBase,
  },
  link5: {
    fontFamily: fonts.Roman,
    fontSize: '16px',
    ...linkBase,
  },
  link6: {
    fontFamily: fonts.Roman,
    fontSize: '14px',
    ...linkBase,
  },
  link1Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '36px',
    ...linkBase,
  },
  link2Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '28px',
    ...linkBase,
  },
  link3Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '22px',
    ...linkBase,
  },
  link4Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '18px',
    ...linkBase,
  },
  link5Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '16px',
    ...linkBase,
  },
  link6Heavy: {
    fontFamily: fonts.Heavy,
    fontSize: '14px',
    ...linkBase,
  },
  callout3: {
    fontFamily: fonts.Heavy,
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  callout4: {
    fontFamily: fonts.Heavy,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  callout5: {
    fontFamily: fonts.Heavy,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  captionText: {
    fontFamily: fonts.Roman,
    fontSize: '12px',
  },
  captionTextMedium: {
    fontFamily: fonts.Medium,
    fontSize: '12px',
  },
  captionTextHeavy: {
    fontFamily: fonts.Heavy,
    fontSize: '12px',
  },
  linkCaption: {
    fontFamily: fonts.Roman,
    fontSize: '12px',
    ...linkBase,
  },
  linkCaptionHeavy: {
    fontFamily: fonts.Heavy,
    fontSize: '12px',
    ...linkBase,
  },
  overlineText: {
    fontFamily: fonts.Black,
    fontSize: '12px',
    color: '#818696',
  },
};

export default typography;
