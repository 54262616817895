import { SxProps } from '@mui/system';
import Spacings from '../ds/theme/common/spacings';

const backToLogin: SxProps = {
  textAlign: 'right',
  '& a': {
    typography: 'link5Heavy',
    marginLeft: Spacings.small2,
  },
};

export default {
  backToLogin,
};
